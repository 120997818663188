import axios, { AxiosInstance } from 'axios';
import { getTokens, setTokens } from './tokenHandler';
import ApiUrls, { API_URL } from './apiUrls';
import refreshToken from './requests/refreshToken';

const instance: AxiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

const urlsForSkippingRefresh = [
  ApiUrls.refreshToken,
  ApiUrls.getToken,
  ApiUrls.getTokenFinal,
  ApiUrls.authSso,
];

// Request interceptor for API calls
instance.interceptors.request.use(
  async (config) => {
    const token =
      config.url === ApiUrls.refreshToken ? getTokens().refreshToken : getTokens().accessToken;
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    console.log('error', error);
    Promise.reject(error);
  }
);

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    try {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        getTokens().accessToken &&
        !originalRequest._retry &&
        !urlsForSkippingRefresh.some((item) => originalRequest.url.includes(item))
      ) {
        originalRequest._retry = true;
        const response = await refreshToken();
        setTokens(response);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.accessToken;
        console.log( 'originalRequest',originalRequest);
        originalRequest.headers['Authorization'] = 'Bearer ' + response.accessToken;
        return instance(originalRequest);
      }

      if (originalRequest.url === ApiUrls.refreshToken) {
        localStorage.clear();
        window.location.reload();
        return;
      }

      return Promise.reject(error?.response?.data?.message);
    } catch (e) {
      console.log('AXIOS error', e);
    }
  }
);

export default instance;

import { getMaintenance } from '@root/services/api/requests/getMaintenance';
import { useEffect, useState } from 'react';

export const useMaintenance = () => {
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);

  useEffect(() => {
    const handleFocus = async () => {
      const response = await getMaintenance();
			
      if (response?.includes('be_pay')) {
        setIsMaintenance(true);
      } else {
        setIsMaintenance(false);
      }
    };

		handleFocus();
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return isMaintenance;
};

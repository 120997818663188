import { useEffect } from 'react';
import apiClient from '@root/services/api/client';
import ApiUrls from '@root/services/api/apiUrls';
import { IAddPayoutResponse } from '@root/services/api/requests/addPayOutInfo';

export default function LeavePageModal() {
  useEffect(() => {
    const sendRequest = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
      apiClient.post<IAddPayoutResponse>(ApiUrls.updateUsingTipalti);
    };

    window.addEventListener('beforeunload', sendRequest);

    return () => {
      window.removeEventListener('beforeunload', sendRequest);
    };
  }, []);

  return null;
}

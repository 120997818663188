import { toast } from 'react-toastify';

interface INotifications {
  message: string | null;
}

const notify = (
  type: 'success' | 'info' | 'error' | 'warning' | 'dark',
  params: INotifications
): void => {
  toast.clearWaitingQueue();

  toast[type](`${params.message}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default notify;

import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { IUserData } from '../../services/api/requests/getPayOutInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHomeUserData,
  selectIsWaitingForWebhook,
  selectPushToCardData,
} from '../../store/system/selectors';
import Button from '@material-ui/core/Button';
import { IPhoneParams } from '../../services/api/requests/verifyPhone';
import { fetchPushToCardData, sendTwilioCode } from '../../store/system/actions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText } from '@material-ui/core';

const labels: { [key: string]: string } = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  countryCode: 'Country',
  state: 'State',
  address1: 'Address Line 1',
  address2: 'Address Line 2',
  city: 'City',
  mailingAddress1: 'Mailing Address Line 1',
  mailingAddress2: 'Mailing Address Line 2',
  mailingCity: 'Mailing City',
  mailingPostalCode: 'Mailing Zip Code',
  mailingState: 'Mailing State',
  postalCode: 'Postal Code',
  method: 'Payment Method',
  taxId: 'Tax ID',
  taxIdType: 'Tax ID Type',
  currency: 'Currency',
  paypalEmail: 'PayPal Email',
  payoneerEmail: 'Payoneer Email',
  bankAddress: 'Bank Address',
  accountHolderName: 'Bank Account Name',
  swiftCode: 'SWIFT Code',
  countryOfBirth: 'Country of Birth',
  dateOfBirth: 'Date of Birth',
  accountType: 'Account Type',
  routingNumber: 'Routing Number',
  bankName: 'Bank Name',
  bankCountry: 'Bank Country',
  bankCity: 'Bank City',
  accountNumber: 'Account Number',
  finInstitutionNumber: 'Financial Institution Number',
  IBAN: 'IBAN',

  beneficiaryFirstName: 'Beneficiary First Name',
  beneficiaryLastName: 'Beneficiary Last Name',
  documentId: 'Document ID',
  bankBranch: 'Bank Branch',
  bankAccount: 'Bank Account',
  bankCode: 'Bank Code',
  documentType: 'Document Type',
  phone: 'Phone',
  isCLABE: 'Is CLABE',
  sortCode: 'Sort Code',
  twilioPhone: 'Phone Number',
  nameOnCard: 'Name on Card',
  last4: 'Last 4 digits',
  expirationDate: 'Expiration Date',
  street: 'Street',
  prepaidCardStatus: 'Prepaid Card Status',
  prepaidCardType: 'Prepaid Card Type',
  prepaidPhysicalCardType: 'Prepaid Physical Card Type',
  placeOfBirth: 'Place of Birth',
  nationality: 'Nationality',
  // physical_cards: 'Number of cards',
};

const dateFns = new DateFnsUtils();

const createValue = (key: string, data: any) => {
  switch (key) {
    case 'dateOfBirth':
      return dateFns.format(new Date(data.dateOfBirth as string), 'dd/MM/yyyy');
    case 'expirationDate':
      return `${data.expirationDate?.month}/${data.expirationDate?.year}`;
    default:
      return data?.[key] && data[key] !== 'undefined' ? data[key] : '';
  }
};

export default function SummaryStep() {
  const dispatch = useDispatch();
  const userData = useSelector(selectHomeUserData);
  const pushToCardData = useSelector(selectPushToCardData);
  const isWaitingForWebhook = useSelector(selectIsWaitingForWebhook);

  const isPushToCardMethod = userData?.method === 'pushToCard';
  const { twilioPhone } = userData;

  const values = Object.keys(userData)
    .filter((item) => !!userData[item as keyof IUserData] && !!labels[item])
    .filter((item) =>
      userData.paymentMethod === 'prepaidCard' ? item : item !== 'prepaidCardType'
    );

  const sendTwilioCodeHandler = (data: IPhoneParams) => dispatch(sendTwilioCode(data));

  useEffect(() => {
    if (isPushToCardMethod) {
      dispatch(fetchPushToCardData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPushToCardMethod]);

  const renderButtons = () => (
    <Button
      fullWidth
      onClick={() => sendTwilioCodeHandler({ phone: twilioPhone as string })}
      variant="outlined"
    >
      Edit
    </Button>
  );

  const pushToCardValues = (isPushToCardMethod && Object.keys(pushToCardData || {})) || [];

  return (
    <>
      <Grid container spacing={2} direction={'column'}>
        {/*{userData.kyckWidgetUrl && <IframeCmp url={userData.kyckWidgetUrl} />}*/}
        <Grid item xs={12}>
          <Box display={'flex'} padding={'0 20px'} alignItems={'center'}>
            <Grid item xs={8} sm={5} lg={3}>
              <Typography variant="h6" component="h6">
                View your BE Pay Details
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Box marginLeft={'20px'}>{renderButtons()}</Box>
            </Grid>
          </Box>

          {!isWaitingForWebhook && (
            <Box display={'flex'} padding={'10px 20px'} alignItems={'center'}>
              Your payout preference has been updated. Please allow some time for the changes to be
              reflected on this page. No need to wait!
            </Box>
          )}

          <List>
            {values.map((item) => {
              const value = createValue(item, userData);

              if (!value) {
                return null;
              }
              return (
                <ListItem divider key={item}>
                  <Grid item xs={6} sm={3}>
                    {labels[item]}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <ListItemText secondary={value} />
                  </Grid>
                </ListItem>
              );
            })}

            {!!userData?.prepaidCards?.physical_cards && (
              <ListItem divider>
                <Grid item xs={6} sm={3}>
                  Number of cards
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ListItemText
                    secondary={`${userData?.prepaidCards?.physical_cards}/3 Physical
                  Cards Max`}
                  />
                </Grid>
              </ListItem>
            )}
          </List>

          {isPushToCardMethod && !!pushToCardData && (
            <List style={{ marginTop: 20 }}>
              {pushToCardValues?.map((item) => {
                const value = createValue(item, pushToCardData);

                if (!value) {
                  return null;
                }
                return (
                  <ListItem divider key={item}>
                    <Grid item xs={6} sm={3}>
                      {labels[item]}
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <ListItemText secondary={value} />
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          )}

          {/*  {dLocal && (*/}
          {/*    <>*/}
          {/*      <Box display={'flex'} padding={'0 20px'} alignItems={'center'}>*/}
          {/*        <Grid item xs={8} sm={5} lg={3}>*/}
          {/*          <Typography variant='h6' component='h6'>*/}
          {/*            Second bank details*/}
          {/*          </Typography>*/}
          {/*        </Grid>*/}
          {/*      </Box>*/}
          {/*      <List>*/}
          {/*        {dLocalValues.map((item: any) => {*/}
          {/*          const value = createValue(item as keyof IUserData, dLocal);*/}
          {/*          return (*/}
          {/*            <ListItem divider key={item}>*/}
          {/*              <Grid item xs={6} sm={3}>*/}
          {/*                {labels[item]}*/}
          {/*              </Grid>*/}
          {/*              <Grid item xs={6} sm={3}>*/}
          {/*                <ListItemText secondary={value} />*/}
          {/*              </Grid>*/}
          {/*            </ListItem>*/}
          {/*          );*/}
          {/*        })}*/}
          {/*      </List>*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*  */}
          {/*  {isPrepaidCardReady && (*/}
          {/*    <>*/}
          {/*      <Box display={'flex'} padding={'0 20px'} alignItems={'center'}>*/}
          {/*        <Grid item xs={8} sm={5} lg={3}>*/}
          {/*          <Typography variant='h6' component='h6'>*/}
          {/*            Prepaid Card details*/}
          {/*          </Typography>*/}
          {/*        </Grid>*/}
          {/*      </Box>*/}
          {/*      <List>*/}
          {/*        {prepaidCardValues?.map((item: any) => {*/}
          {/*          const value = createValue(item as keyof IUserData, prepaidCard);*/}
          {/*          */}
          {/*          */}
          {/*          if (!value || value === 'prepaidCard') {*/}
          {/*            return null;*/}
          {/*          }*/}
          {/*          return (*/}
          {/*            <ListItem divider key={item}>*/}
          {/*              <Grid item xs={6} sm={3}>*/}
          {/*                {labels[item] || prepaidCardLabels[item]}*/}
          {/*              </Grid>*/}
          {/*              <Grid item xs={6} sm={3}>*/}
          {/*                <ListItemText secondary={value} />*/}
          {/*              </Grid>*/}
          {/*            </ListItem>*/}
          {/*          );*/}
          {/*        })}*/}
          {/*      </List>*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*  */}
          {/*  */}
          {/*  {renderButtons()}*/}
        </Grid>
      </Grid>
    </>
  );
}

import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { ISSOResponse } from './authSso';

export type IUserData = {
  username: string;
  password: string;
  instanceType?: string;
};

export interface IRedirectionToken extends ISSOResponse {
  redirectionToken: string;
}

export interface IRedirectionTokenResponse {
  data: IRedirectionToken | { data: IRedirectionToken };
}

const request = (data: IUserData) =>
  apiClient.post<IRedirectionTokenResponse>(ApiUrls.getToken, data).then((response) => response);

export default request;

import { History } from 'history';
import * as React from 'react';
import HomePage from './pages/Payout';
import LoginPage from '@root/pages/Login';
import ForgotUsername from './pages/ForgotUsername';
import WithRoot from './WithRoot';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import pageLinks from './utils/pageLinks';
import 'react-toastify/dist/ReactToastify.css';
import InvoicesPage from './pages/TaxInvoices';
import { useMaintenance } from '@root/hooks/useMaintenance';
import Maintenance from '@root/pages/Maintenance';

interface IAppProps {
  history: History;
}

const App = ({ history }: IAppProps) => {
  const isMaintenanceChecked = useMaintenance();

  return (
    <>
      {isMaintenanceChecked ? (
        <Maintenance />
      ) : (
        <WithRoot>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path={pageLinks.login} component={LoginPage} />
              <Route path={pageLinks.forgotUsername} component={ForgotUsername} />
              <Route path={pageLinks.invoices} component={InvoicesPage} />
              <Route path={pageLinks.home} component={HomePage} />
            </Switch>
          </ConnectedRouter>
          <ToastContainer />
        </WithRoot>
      )}
    </>
  );
};

export default App;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    paperModal: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      backgroundColor: '#fff',
    },
    instructions: {
      display: 'block',
      textAlign: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: 16,
      fontWeight: 'bold',
      // textShadow: `2px 2px 10px ${theme.palette.primary.main}`,
      color: 'red',
    },
    
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    
    formContainer: {
      maxWidth: 400,
    },
    formControlSelect: {
      width: '100%',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    paymentTitle: {
      margin: theme.spacing(2, 0, 2),
    },
    mailingAddress: {
      margin: theme.spacing(0, 2, 0, 0),
    },
    phone: {
      marginLeft: 10,
    },
  }),
);

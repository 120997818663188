import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';

export interface ITaxInvoiceItem {
  invoiceId: number;
  invoiceDate: string;
  amount: number;
  fee: number;
  commissionPeriod: string;
  invoiceUrl: string;
}

export interface ITaxInvoiceParams {
  page: number;
  limit: number;
  startDate?: string;
  endDate?: string;
}

const request = (params: ITaxInvoiceParams) => {
  
  const query = queryString.stringify(params);

  return apiClient
    .get<AxiosResponse<any>>(ApiUrls.taxInvoice + `?${query}`)
    .then((response) => response.data.data);
};

export default request;

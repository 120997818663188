import React from 'react';
import { useStyles } from './styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { GridSize } from '@material-ui/core/Grid/Grid';

interface IProps {
  country: string;
  id: string;
  label: string;
  value: string;
  required: boolean;
  errorMessage: string;
  helperText?: string;
  disabled?: boolean;
  error: boolean;
  className: string;
  xs?: GridSize;

  onChange(e: React.ChangeEvent<any>): void;

  onBlur(e: React.FocusEvent): void;

  setFieldValue(a: any, b: any): void;
}

const InputPhone = ({
  id,
  label,
  value,
  required,
  helperText,
  onChange,
  setFieldValue,
  error,
  onBlur,
  disabled,
  className,
  xs,
}: IProps) => {
  const styles = useStyles();

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replaceAll(' ', '');
    setFieldValue(id, value);
  };

  return (
    <Grid item xs={xs || 10} className={styles.wrapper}>
      <TextField
        variant="outlined"
        fullWidth
        id={id}
        label={label}
        name={id}
        error={error}
        onBlur={onBlur}
        helperText={helperText}
        type={'tel'}
        value={value}
        className={className}
        onChange={onPhoneChange}
        disabled={disabled}
        required={required}
        inputProps={{ maxLength: 12 }}
      />
    </Grid>
  );
};

export default InputPhone;

import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { AxiosResponse } from 'axios';
import { IResponseAxios } from '@root/services/types';

export interface IVerifyResponse {

  valid: boolean;
  code: string;

}


export interface IPhoneParams {
  phone: string;
  code?: string;
}

const request = ({ phone, code }: IPhoneParams) =>
  apiClient
    .post<IResponseAxios<IVerifyResponse>>(ApiUrls.phoneVerification, { code, phone })
    .then((response) => response);

export default request;

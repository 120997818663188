import { getTokens } from '../services/api/tokenHandler';
import sortBy from 'lodash/sortBy';
import { countries } from 'countries-list';
import { IOption } from '../types';
import { dLocalCountries } from '@root/constants/countries';

export const getIsTipaltyFlow = ({ method, country }: { method: string; country: string }) => {
  const isDLocalFlow = method === 'bankWire' && dLocalCountries.includes(country as string);

  return !isDLocalFlow && ['bankWire', 'ACH'].includes(method);
};

export function getSteps(method: string, country: string, createdAt: string) {
  const isTipaltyFlow =
    method &&
    getIsTipaltyFlow({
      method,
      country,
    });

  if (
    (method && ['cash', 'pushToCard', 'prepaidCard', 'PayPal'].includes(method)) ||
    isTipaltyFlow
  ) {
    return ['Payment preferences', 'Payee details', 'BE Pay Details'];
  }
  return ['Payment preferences', 'Payee details', 'Payment method', 'BE Pay Details'];
}

export const anyLetterRegExp =
  /^[a-zA-Z\u00C0-\u00FFŸŒ¢]+([a-zA-Z\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢]+$/;

export const normalizeLatinLetters = (text = '') => {
  return (
    text
      .normalize('NFKD')
      .replace(/[\u0080-\uF8FF\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E]/g, ' ') || ''
  );
};

export const isUserLogged = () => getTokens().accessToken;

export const isDesktop = () => {
  if (window.innerWidth <= 480) {
    return false;
  } else {
    return true;
  }
}

export const getCountryCodeOptions = () =>
  Object.entries(sortBy(countries, 'phone')).reduce((acc: IOption[], value) => {
    const phoneCode = value[1].phone;
    const flag = value[1].emoji;

    const createOption = (phone: string, flag: string) => {
      const label = `+${phone}`;
      const value = `${label}`;
      const exist = acc.find((option) => option.value === value);

      if (!exist) {
        acc.push({
          value,
          label,
          flag,
        });
      }
    };

    if (phoneCode.includes(',')) {
      phoneCode.split(',').map((code) => createOption(code, flag));
    } else {
      createOption(phoneCode, flag);
    }

    return acc;
  }, []);

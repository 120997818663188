import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#fff',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    note: {
      textAlign: 'center',
      textShadow: `2px 2px 10px ${theme.palette.primary.main}`,
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      boxShadow: 'none',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%', // Fix IE 11 issue.
      marginTop: 10,
    },
    countryLabel: {
      width: '100%', // Fix IE 11 issue.
    },
    mainGrid: {
      position: 'relative',
    },
    editButton: {
      marginRight: '20px',
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '50px',
      width: '100%',
      height: '100%',
    },

    paperModal: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      backgroundColor: '#fff',
    },

    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    fixedLoader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.52)',
      zIndex: 100,
    },
  })
);

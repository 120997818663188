import * as React from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormikProps, useFormik } from 'formik';
import { IDLocalInputFields, IFields, IVDLocalBank } from './types';
import { createValidation } from './dLocal';
import { selectHomeEdit } from '../../store/system/selectors';
import { checkBoxCreator, inputCreator, selectCreator } from '../BaseForm';
import CustomButtons from '../CustomButtons';
import useStyles from './styles';

interface IProps {
  formData: {
    onSubmit(values: IDLocalInputFields): void;
    validationRules: any;
    fields: IFields[];
    initialValues: IDLocalInputFields;
    bankCodes: any;
  };
}

export default function PaymentMethodStep(props: IProps) {
  const classes = useStyles();
  const canEdit = useSelector(selectHomeEdit);

  const {
    formData: { validationRules, initialValues, fields, onSubmit, bankCodes },
  } = props;

  const [bankValidation, setBankValidation] = React.useState<any>(null);

  let schema = () => {
    return Yup.object().shape({
      ...validationRules,
      ...(bankValidation || {}),
    });
  };

  const fk: FormikProps<IDLocalInputFields> = useFormik({
    initialValues,
    validationSchema: schema(),
    // validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values: IDLocalInputFields) => {
      if (!fk.isValid) {
        return;
      }
      onSubmit(values);
    },
  });

  // @ts-ignore
  const bankCode = fk.values.bankCode;

  React.useEffect(() => {
    // @ts-ignore
    if (bankCode) {
      const { name, code, ...rest } =
        bankCodes.find((item: IVDLocalBank) => item.code === bankCode) || {};

      const fields: any = {};

      Object.keys(rest).forEach((item) => {
        if (initialValues.hasOwnProperty(item)) {
          fields[item] = rest[item];
        }
      });

      const validation = Object.keys(fields).length ? createValidation(fields) : null;

      setBankValidation(validation);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankCode]);

  React.useEffect(() => {
    if (bankCode) {
      const bankItem = bankCodes.find((item: IVDLocalBank) => item.code === bankCode);
      bankItem?.name && fk.setFieldValue('bankName', bankItem?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankCode]);

  const createInput = inputCreator<IDLocalInputFields>(fk);
  const createSelect = selectCreator<IDLocalInputFields>(fk);
  const createCheckbox = checkBoxCreator<IDLocalInputFields>(fk);

  const renderFields = (): React.ReactNode => {
    return fields.filter(Boolean).map((field: any) => {
      const { label, id, inputType, options, helperText } = field;

      const disabled = !canEdit;

      if (inputType === 'select') {
        return createSelect({ label, id, options, disabled, helperText } as any);
      }

      if (inputType === 'checkbox') {
        return createCheckbox({ label, id, disabled } as any);
      }

      return createInput({ label, id, disabled, helperText } as any);
    });
  };

  console.warn('FK', fk);

  return (
    <form className={classes.form} onSubmit={fk.handleSubmit}>
      <Grid item xs={12} className={classes.paymentTitle}>
        <Typography variant="h6" component="h6">
          Bank Account
        </Typography>
      </Grid>

      <Grid container spacing={2}>
        {renderFields()}
      </Grid>

      <div className={classes.paymentTitle}>
        {canEdit ? (
          <CustomButtons resetForm={() => fk.handleReset()} isDisabled={!fk.isValid} />
        ) : (
          <div />
        )}
      </div>
    </form>
  );
}

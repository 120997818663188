import React from 'react';
import validateRoutingNumber from '../services/api/requests/validateRoutingNumber';

const useRoutingNumberValidator = () => {
  const [routingNumber, checkRoutingNumber] = React.useState<string | null>(null);
  const [routingNumberError, setError] = React.useState<string | null>(null);

  const validate = async () => {
    try {
      const response = await validateRoutingNumber(routingNumber as string);
      const isValid = response?.data?.data;
      if (!isValid && response?.data?.statusCode === 200) {
        setError('Routing number is invalid. Please try another one');
      }

      if (isValid && !!routingNumberError) {
        setError(null);
      }
    } catch (e) {
      console.warn('routing number error', e);
    }
  };

  React.useEffect(() => {
    routingNumber && validate();
    return () => {
      setError(null);
      checkRoutingNumber(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routingNumber]);

  return {
    routingNumberError,
    validateRoutingNumber: (value: string) => checkRoutingNumber(value),
  };
};

export default useRoutingNumberValidator;

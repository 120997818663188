export const circleSupportedCountries = [
  'AD',
  'AO',
  'AT',
  'AZ',
  'BE',
  'BJ',
  'BG',
  'CM',
  'TD',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GI',
  'GR',
  'GT',
  'GG',
  'VA',
  'HU',
  'IS',
  'IE',
  'IM',
  'IL',
  'IT',
  'JE',
  'JO',
  'LV',
  'LI',
  'LT',
  'LU',
  'MG',
  'MT',
  'MC',
  'MZ',
  'NL',
  'NE',
  'NO',
  'PL',
  'PT',
  'RO',
  'SM',
  'GG',
  'VA',
  'HU',
  'IS',
  'IE',
  'IM',
  'IL',
  'IT',
  'JE',
  'JO',
  'LV',
  'LI',
  'LT',
  'LU',
  'MG',
  'MT',
  'MC',
  'MZ',
  'NL',
  'NE',
  'NO',
  'PL',
  'PT',
  'RO',
  'SM',
  'SN',
  'SI',
  'ES',
  'SE',
  'CH',
  'TR',
  'AE',
  'VG',
  'SN',
  'SI',
  'ES',
  'SE',
  'CH',
  'TR',
  'AE',
  'VG',
  'GB',
];

export const isCircleSupportedCountry = (country: string) =>
  circleSupportedCountries.includes(country);

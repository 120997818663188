import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputContainer: {
      width: '100%',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
    },
    links: {
      marginTop: 10,
      padding: 8,
    },
    submit: {
      height: '50px',
      margin: theme.spacing(3, 1, 2),
    },
    input: {
      marginBottom: 30,
    },
    fixedLoader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.52)',
      zIndex: 100,
    },
  })
);

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    back: {
      alignSelf: 'flex-start',
      width: 70,
      marginTop: 20,
      marginLeft: 30,
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      textAlign: 'center',
      marginBottom: '40px',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      height: '50px',
      margin: theme.spacing(3, 0, 2),
    },
    input: {
      marginBottom: 30,
    },
  })
);

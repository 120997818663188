import { call, delay, put, select, spawn, takeLatest, SagaReturnType } from 'redux-saga/effects';
import { resetHomePage, saveUserData, setLoader } from '../store/system/actions';
import authSso, { ISSOResponse } from '../services/api/requests/authSso';
import getTokenFinal, { ITokenData } from '../services/api/requests/getTokenFinal';
import { setTokens } from '../services/api/tokenHandler';
import setNotification from '../utils/notifications';
import getToken, { IRedirectionToken } from '../services/api/requests/getToken';
import { LOGOUT, START_DEFAULT_LOGIN, StartDefaultLoginAction } from '../store/system/types';
import { push } from 'connected-react-router';
import pageLinks from '../utils/pageLinks';
import { selectUserData } from '../store/system/selectors';

const isDevelopmentMode = process.env.NODE_ENV === `development`;
// const isDevelopmentMode = false;

function showError(message: string) {
  console.log('message', message);

  let text = message || 'Something went wrong, please try again later';

  if (message?.includes('expired')) {
    text = 'Your session is expired. Please re-login';
  }

  if (message?.toLowerCase?.().includes('invalid login name or password')) {
    text = 'Invalid credentials, please try again';
  }

  setNotification('error', {
    message: text,
  });
}

const checkAndRedirect = (data: ISSOResponse) => {
  const { wrongDomain, redirectionUrl, redirectionToken } = data;

  if (wrongDomain && !isDevelopmentMode) {
    window.location.href = `https://${redirectionUrl}?tempToken=${redirectionToken}`;
    return false;
  }
  return true;
};

function* loginWorker(action: StartDefaultLoginAction) {
  yield put(setLoader(true));
  try {
    const response: SagaReturnType<typeof getToken> = yield call(getToken, action.payload);

    const responseData = (response?.data?.data || response?.data) as IRedirectionToken;

    const valid: ReturnType<typeof checkAndRedirect> = yield call(checkAndRedirect, responseData);
    if (valid) {
      setTokens({
        accessToken: responseData.redirectionToken,
        refreshToken: 'dummyToken',
      });
      yield call(finalTokenWorker);
      yield put(push(pageLinks.home));
    }
  } catch (e) {
    yield call(showError, e);
  } finally {
    yield put(setLoader(false));
  }
}

function* startSSO() {
  const { accessToken, refreshToken } = yield select(selectUserData);

  const beBackOfficeToken = new URLSearchParams(new URL(window.location.href).search).get('Token');
  const temporaryToken = new URLSearchParams(new URL(window.location.href).search).get('tempToken');

  if (accessToken && refreshToken) {
    if (beBackOfficeToken || temporaryToken) {
      yield call(clearData);
    } else {
      return;
    }
  }

  if (beBackOfficeToken) {
    // We decrypt bebackoffice token and received our JWT for finalToken request
    try {
      const response: SagaReturnType<typeof authSso>  = yield call(authSso, beBackOfficeToken);
      // @ts-ignore
      const data = (response?.data?.data || response?.data) as unknown as ISSOResponse;

      const valid:ReturnType<typeof checkAndRedirect> = yield call(checkAndRedirect, data);

      if (valid) {
        setTokens({
          // @ts-ignore
          accessToken: data.redirectionToken || data.data.refreshToken,
          refreshToken: 'dummyToken',
        });
        yield call(finalTokenWorker);
        // yield put(push(pageLinks.home));
      }
    } catch (e) {
      yield call(showError, e);
      yield put(push(pageLinks.login));
    }
  } else if (temporaryToken) {
    // user already have JWT and want just request finalToken login
    setTokens({
      accessToken: temporaryToken,
      refreshToken: 'dummyToken',
    });
    yield call(finalTokenWorker);
    // yield put(push(pageLinks.home));
  } else {
    yield put(push(pageLinks.login));
  }
}

function* finalTokenWorker() {
  try {
    const finalTokenResponse: SagaReturnType<typeof getTokenFinal> = yield call(getTokenFinal);
    const response = (finalTokenResponse?.data.data || finalTokenResponse?.data) as ITokenData;
    const { accessToken, refreshToken, userId } = response;
    setTokens({ accessToken, refreshToken });
    yield delay(0);
    yield put(saveUserData({ accessToken, refreshToken, userId } as ITokenData));
  } catch (e) {
    yield call(showError, e);
    yield call(logOutUser);
  }
}

function* logOutUser() {
  yield put(resetHomePage());
  localStorage.clear();
  yield put(push(pageLinks.login));
}

function* clearData() {
  yield put(resetHomePage());
  localStorage.clear();
}

export default function* authSaga() {
  yield spawn(startSSO);
  yield takeLatest(START_DEFAULT_LOGIN, loginWorker);
  yield takeLatest(LOGOUT, logOutUser);
}

import { RootState } from '../index';
import { createSelector } from '@reduxjs/toolkit';
import { restrictedCountries, IRegion } from '../../utils/regions';
import { IOption } from '../../types';

const selectEntities = (state: RootState) => state.entities;

export const selectRegions = createSelector(selectEntities, (state) => state.regions);

export const selectCountryList = createSelector(selectRegions, (regions) => {
  return regions
    .map((region: IRegion) =>
      region.countries.map((country) => ({ label: country.name, value: country.code }))
    )
    .reduce((acc: IOption[], val: IOption[]) => [...acc, ...val], [])
    .filter((item: IOption) => !restrictedCountries.includes(item.label));
});

import apiClient from '../client';
import ApiUrls from '../apiUrls';

export interface IRefreshResponse {
  accessToken: string;
  refreshToken: string;
}

const request = (): Promise<IRefreshResponse> =>
  apiClient.post(ApiUrls.refreshToken).then((response) => response.data.data).catch((error) => null);

export default request;

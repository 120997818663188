import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormikProps } from 'formik';
import { IOption } from '../../types';
import * as React from 'react';
import InputSelect from '../InputSelect';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export const useStandardFormStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formContainer: {
    maxWidth: 600,
  },
  formControlSelect: {
    width: '100%',
  },
  textWrap: {
    whiteSpace: 'normal',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  checkbox: {
    display: 'flex',
  },
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },

  textSizeSmall: {
    textTransform: 'none',
  },
}));

interface IProps<T> {
  id: keyof T;
  label: string;
  md?: 6 | 12;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  errorText?: string | null;
  helperText?: string;
  explanationText?: string;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
}

interface IPropsSelect<T> extends IProps<T> {
  options: IOption[];
}

export const selectCreator: <T>(
  fk: FormikProps<T>,
  globalFormDisabled?: boolean
) => (props: IPropsSelect<T>) => React.ReactNode = (fk, globalFormDisabled) => (props) => {
  const { id, label, md, type, isRequired, options, disabled } = props;
  return (
    <Grid item xs={12} md={md || 6}>
      <InputSelect
        name={String(id)}
        variant="outlined"
        fullWidth
        required={!!isRequired}
        id={String(id)}
        type={type || 'text'}
        label={label}
        error={!!(fk.errors[id] && fk.touched[id])}
        // @ts-ignore
        errorMessage={(fk.touched[id] && fk.errors[id]) || ''}
        onBlur={fk.handleBlur}
        value={fk.values[id]}
        onChange={fk.handleChange}
        options={options}
        disabled={globalFormDisabled || disabled}
      />
    </Grid>
  );
};

export const inputCreator: <T>(
  fk: FormikProps<T>,
  globalFormDisabled?: boolean
) => (props: IProps<T>) => React.ReactNode = (fk, globalFormDisabled) => (props) => {
  const {
    id,
    label,
    md,
    type,
    isRequired,
    disabled,
    onBlur,
    errorText,
    helperText,
    explanationText,
  } = props;

  const [showPassword, togglePassword] = React.useState<boolean>(false);
  const isPassword = type === 'password';

  const typeHandler = () => {
    if (isPassword) {
      return showPassword ? 'text' : 'password';
    }

    return type || 'text';
  };

  return (
    <Grid item xs={12} md={md || 6}>
      <TextField
        autoComplete={String(id)}
        name={String(id)}
        variant="outlined"
        fullWidth
        required={!!isRequired}
        id={String(id)}
        type={typeHandler()}
        label={label}
        error={(!!fk.errors[id] && !!fk.touched[id]) || !!errorText}
        onBlur={onBlur || fk.handleBlur}
        helperText={(fk.touched[id] && fk.errors[id]) || errorText || helperText}
        value={fk.values[id]}
        onChange={fk.handleChange}
        disabled={!!globalFormDisabled || disabled}
        InputLabelProps={{
          shrink: !!fk.values[id],
        }}
        InputProps={
          isPassword
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => togglePassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
      {explanationText && <span className={'inputInfo'}>{explanationText}</span>}
    </Grid>
  );
};

export const checkBoxCreator: <T>(fk: FormikProps<T>) => (props: IProps<T>) => React.ReactNode = (
  fk
) => (props) => {
  const { id, label, md, disabled } = props;
  return (
    <Grid item xs={12} md={md || 6}>
      <Box display={'flex'} alignItems={'center'}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={!!fk.values[id]}
              onChange={fk.handleChange}
              disabled={disabled}
              // @ts-ignore
              name={id}
            />
          }
          label={label}
        />
      </Box>
    </Grid>
  );
};

import { IRegion } from '../../utils/regions';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const FETCH_START_DATA = 'FETCH_START_DATA';
export const FETCH_ADMIN_ROLES_LIST = 'FETCH_ADMIN_ROLES_LIST';
export const EDIT_ADMIN_USER = 'EDIT_ADMIN_USER';
export const FETCH_ADMIN_ROLES_LIST_SUCCESS = 'FETCH_ADMIN_ROLES_LIST_SUCCESS';
export const EDIT_COUPON = 'EDIT_COUPON';
export const SAVE_REGIONS = 'SAVE_REGIONS';

export interface SaveRegionsAction {
  type: typeof SAVE_REGIONS;
  payload: IRegion;
}

export interface EntitiesState {
  readonly regions: any;
}

export type ActionTypes = SaveRegionsAction;


const theme = {
    colors: {
        primaryText: '#fff'
    },
    palette: {
        primary: {
            contrastText: '#fff',
            main: '#3d5afe'
        }
    },
    transparentBackground: {
        dark: 'rgba(0, 0, 0, 0.15)',
        light: 'rgba(255, 255, 255, 0.15)'
    }
};


export default theme;

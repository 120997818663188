import apiClient from '../client';
import ApiUrls from '../apiUrls';

export interface IPushToCardData {
  data: any;
}

const getPushToCardData = async () => {
  try {
    const response = await apiClient.get<IPushToCardData>(ApiUrls.pushToCardData);
    return response.data.data;
  } catch (e) {
    return null;
  }
};


export default getPushToCardData;

import {
  CHANGE_THEME,
  ChangeThemeAction,
  FETCH_PREPAID_CARD_PLAN,
  FETCH_PUSH_TO_CARD_DATA,
  FETCH_USER_DATA,
  FetchPrepaidCardPlanAction,
  FetchPushToCardDataAction,
  FetchUserDataAction,
  HOME_PAGE_RESET,
  HomePageReset,
  IHomeUserData,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LogInAction,
  LogInSuccessAction,
  LOGOUT,
  LogOutAction,
  SAVE_EDIT_DATA,
  SaveEditDataAction,
  SEND_TWILIO_CODE,
  SendTwilioCodeAction,
  SET_HOME_CONFIRMATION_MODAL,
  SET_HOME_EDIT,
  SET_HOME_FIXED_LOADER,
  SET_HOME_INITIAL_DATA,
  SET_HOME_LOADER,
  SET_HOME_STEP,
  SET_HOME_STEP_LIST,
  SET_HOME_SUCCESS_MESSAGE,
  SET_HOME_USER_DATA,
  SET_LOADER,
  SET_PREPAID_CARD_MODAL,
  SET_PUSH_TO_CARD_DATA,
  SET_WAITING_FOR_WEBHOOK,
  SetEditAction,
  SetHomeConfirmationModalAction,
  SetHomeFixedLoaderAction,
  SetHomeInitialDataAction,
  SetHomeLoaderAction,
  SetHomeSuccessMessageAction,
  SetHomeUserDataAction,
  SetLoaderAction,
  SetPrepaidCardModalAction,
  SetPushToCardDataAction,
  SetStepAction,
  SetStepListAction,
  SetWaitingForWebhook,
  START_DEFAULT_LOGIN,
  StartDefaultLoginAction,
  VERIFY_TWILIO_CODE,
  VerifyTwilioCodeAction,
} from './types';
import { ITokenData } from '../../services/api/requests/getTokenFinal';
import { IUserData } from '../../services/api/requests/getToken';
import { IPhoneParams } from '../../services/api/requests/verifyPhone';
import { IUserData as IUserDataHome } from '../../services/api/requests/getPayOutInfo';
import { IPushToCardData } from '@root/services/api/requests/getPushToCardData';

export function startDefaultLogin(payload: IUserData): StartDefaultLoginAction {
  return {
    type: START_DEFAULT_LOGIN,
    payload,
  };
}

export function changeTheme(payload: string): ChangeThemeAction {
  return {
    type: CHANGE_THEME,
    payload,
  };
}

export function setWaitingForWebhook(payload: boolean): SetWaitingForWebhook {
  return {
    type: SET_WAITING_FOR_WEBHOOK,
    payload,
  };
}

export function logOutUser(): LogOutAction {
  return {
    type: LOGOUT,
  };
}

export function logInUser(payload: any): LogInAction {
  return {
    type: LOGIN_USER,
    payload,
  };
}

export function saveUserData(payload: ITokenData): LogInSuccessAction {
  return {
    type: LOGIN_USER_SUCCESS,
    payload,
  };
}

export function setLoader(payload: boolean): SetLoaderAction {
  return {
    type: SET_LOADER,
    payload,
  };
}

export function setHomeStep(payload: number): SetStepAction {
  return {
    type: SET_HOME_STEP,
    payload,
  };
}

export function setHomeStepList(payload: string[] | null): SetStepListAction {
  return {
    type: SET_HOME_STEP_LIST,
    payload,
  };
}

export function setHomeEdit(payload: boolean): SetEditAction {
  return {
    type: SET_HOME_EDIT,
    payload,
  };
}

export function setHomeUserData(payload: IHomeUserData): SetHomeUserDataAction {
  return {
    type: SET_HOME_USER_DATA,
    payload,
  };
}

export function setHomeInitialData(payload: IHomeUserData | {}): SetHomeInitialDataAction {
  return {
    type: SET_HOME_INITIAL_DATA,
    payload,
  };
}

export function setHomeLoader(payload: boolean): SetHomeLoaderAction {
  return {
    type: SET_HOME_LOADER,
    payload,
  };
}

export function setHomeFixedLoader(payload: boolean): SetHomeFixedLoaderAction {
  return {
    type: SET_HOME_FIXED_LOADER,
    payload,
  };
}

export function setHomeConfirmationModal(payload: boolean): SetHomeConfirmationModalAction {
  return {
    type: SET_HOME_CONFIRMATION_MODAL,
    payload,
  };
}

export function fetchPrepaidCardPlan(payload: string): FetchPrepaidCardPlanAction {
  return {
    type: FETCH_PREPAID_CARD_PLAN,
    payload,
  };
}

export function setPrepaidCardModal(payload: boolean): SetPrepaidCardModalAction {
  return {
    type: SET_PREPAID_CARD_MODAL,
    payload,
  };
}

export function setHomeSuccessMessage(payload: boolean): SetHomeSuccessMessageAction {
  return {
    type: SET_HOME_SUCCESS_MESSAGE,
    payload,
  };
}

export function resetHomePage(): HomePageReset {
  return { type: HOME_PAGE_RESET };
}

export function sendTwilioCode(payload: IPhoneParams): SendTwilioCodeAction {
  return {
    type: SEND_TWILIO_CODE,
    payload,
  };
}

export function verifyTwilioCode(payload: IPhoneParams): VerifyTwilioCodeAction {
  return {
    type: VERIFY_TWILIO_CODE,
    payload,
  };
}

export function saveEditData(payload: IUserDataHome): SaveEditDataAction {
  return {
    type: SAVE_EDIT_DATA,
    payload,
  };
}

export function fetchUserData(): FetchUserDataAction {
  return { type: FETCH_USER_DATA };
}

export function fetchPushToCardData(): FetchPushToCardDataAction {
  return { type: FETCH_PUSH_TO_CARD_DATA };
}

export function setPushToCardData(payload: IPushToCardData): SetPushToCardDataAction {
  return {
    type: SET_PUSH_TO_CARD_DATA,
    payload,
  };
}

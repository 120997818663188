import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { IVDLocalCountryInfo } from '../../../components/DLocalForm/types';

export interface IVerifyResponse {
  data: IVDLocalCountryInfo;
}

export interface IRequestBody {
  countryCode: string;
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  documentId: string;
  documentType: string;
  bankName: string;
  bankCode: string;
  accountType: string;
  bankBranch: string;
  bankAccount: string;
  IBAN: string;
  isCLABE: boolean;
  phone: string;
}

const request = (data: IRequestBody) =>
  apiClient.post<IVerifyResponse>(ApiUrls.dLocal, data).then((response) => response);

export default request;

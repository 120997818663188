import * as Yup from 'yup';
import { anyLetterRegExp } from '../../utils/helpers';
import { isCircleSupportedCountry } from '../../utils/circleCountries';

const validationSchemaPayPal = Yup.object().shape({
  paypalEmail: Yup.string().required('Email is required'),
});

const validationSchemaAch = Yup.object().shape({
  bankName: Yup.string()
    .min(2, 'Bank name should contain at least two letters')
    .max(50, '50 characters max')
    .required('Bank name is required')
    .matches(anyLetterRegExp, 'These characters are not supported'),

  routingNumber: Yup.string().when('countryCode', (val: string) => {
    const condition = val === 'CA';
    if (condition) {
      return Yup.string()
        .required('Routing number is required')
        .min(5, 'must be 5 characters long')
        .max(5, 'must be 5 characters long')
        .matches(/^[0-9]+$/, 'These characters are not supported');
    }
    return Yup.string()
      .required('Routing number is required')
      .min(9, 'must be 9 characters long')
      .max(9, 'must be 9 characters long')
      .matches(/^[0-9]+$/, 'These characters are not supported');
  }),

  finInstitutionNumber: Yup.string().when('countryCode', (val: string) => {
    const condition = val === 'CA';
    if (condition) {
      return Yup.string()
        .required('Financial Institution Number is required')
        .matches(/^[0-9]+$/, 'These characters are not supported');
    }
    return Yup.string();
  }),

  accountNumber: Yup.string()
    .required('Account number is required')
    .max(32, '32 digits max')
    .matches(/^[0-9]+$/, 'These characters are not supported'),

  IBAN: Yup.string()
    .required('IBAN is required')
    .max(30, "IBAN shouldn't be longer than 30 characters")
    .matches(/(^[a-zA-Z0-9]+$|^(N\/A\b))+$/, 'These characters are not supported'),

  swiftCode: Yup.string()
    .required('Swift code is required')
    .max(11, '11 digits max')
    .matches(/(^[a-zA-Z0-9]+$|^(N\/A\b))+$/, 'These characters are not supported'),

  confirmAccountNumber: Yup.string()
    .required('Confirm Account number is required')
    .oneOf(
      [Yup.ref('accountNumber'), ''],
      'Confirm Account number and Account number do not match'
    ),
});

const validationSchemaBankWireBolivia = Yup.object().shape({
  beneficiaryLastName: Yup.string()
    .required('Beneficiary Last name is required')
    .matches(/^[A-Za-z]{1,50}$/, 'These characters are not supported'),

  beneficiaryFirstName: Yup.string()
    .required('Beneficiary First name is required')
    .matches(/^[A-Za-z]{1,50}$/, 'These characters are not supported'),

  bankName: Yup.string()
    .required('Bank name is required')
    .min(2, 'Bank name should contain at least two letters')
    .matches(
      /^[a-zA-Z\u00C0-\u00FFŸŒ¢0-9]+([a-zA-Z\u00C0-\u00FFŸŒ¢0-9\s]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢0-9]+$/,
      'These characters are not supported'
    )
    .max(50, '50 characters max'),

  bankAddress: Yup.string()
    .required('Bank name is required')
    .min(2, 'Bank name should contain at least two letters')
    .matches(
      /^[a-zA-Z\u00C0-\u00FFŸŒ¢0-9]+([a-zA-Z\u00C0-\u00FFŸŒ¢0-9\s]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢0-9]+$/,
      'These characters are not supported'
    )
    .max(50, '50 characters max'),

  bankCity: Yup.string()
    .required('Bank name is required')
    .min(2, 'Bank name should contain at least two letters')
    .matches(
      /^[a-zA-Z\u00C0-\u00FFŸŒ¢0-9]+([a-zA-Z\u00C0-\u00FFŸŒ¢0-9\s]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢0-9]+$/,
      'These characters are not supported'
    )
    .max(50, '50 characters max'),

  accountNumber: Yup.string()
    .required('Account number is required')
    .max(32, '32 digits max')
    .matches(/^[0-9]+$/, 'These characters are not supported'),

  IBAN: Yup.string()
    .max(30, "IBAN shouldn't be longer than 30 characters")
    .matches(/(^[a-zA-Z0-9]+$|^(N\/A\b))+$/, 'These characters are not supported'),

  swiftCode: Yup.string()
    .required('Swift code is required')
    .max(11, '11 digits max')
    .matches(/(^[a-zA-Z0-9]+$|^(N\/A\b))+$/, 'These characters are not supported'),
});

const validationSchemaBankWireDefault = Yup.object().shape({
  finInstitutionNumber: Yup.string().when('countryCode', (val: string) => {
    const condition = val === 'CA';
    if (condition) {
      return Yup.string()
        .required('Financial Institution Number is required')
        .matches(/^[0-9]+$/, 'These characters are not supported');
    }
    return Yup.string();
  }),

  routingNumber: Yup.string().when('countryCode', (val: string) => {
    const condition = val === 'CA';
    if (condition) {
      return Yup.string()
        .required('Routing number is required')
        .min(5, 'must be 5 characters long')
        .max(5, 'must be 5 characters long')
        .matches(/^[0-9]+$/, 'These characters are not supported');
    }
    return Yup.string();
  }),

  accountHolderName: Yup.string()
    .required('Name on Account is required')
    .min(2, 'Name on Account should contain at least two letters')
    .max(50, '50 characters max')
    .matches(anyLetterRegExp, 'These characters are not supported'),

  dateOfBirth: Yup.string().required('Date of Birth is required'),

  countryOfBirth: Yup.string().required('Country of birth is required'),

  sortCode: Yup.string().when('countryCode', (val: string) => {
    if (val === 'GB') {
      return Yup.string()
        .required('Sort Code is required')
        .test(
          'is-valid',
          'sort code must be 2 or 4 or 6 characters long and format 0-9',
          (value) => {
            return value
              ? ['^[0-9]{2}$', '^[0-9]{4}$', '^[0-9]{6}$'].some((item) =>
                  new RegExp(item).test(value as string)
                )
              : true;
          }
        );
    }
    return Yup.string();
  }),

  accountNumber: Yup.string().when('countryCode', (val: string) => {
    if (val === 'GB') {
      return Yup.string()
        .required('Account Number is required')
        .matches(/^[0-9]{16}$/, 'Account Number must be 16 characters long');
    } else {
      return Yup.string()
        .required('Account number is required')
        .max(39, '39 digits max')
        .matches(/^[a-zA-Z0-9]+$/, 'These characters are not supported');
    }
  }),
  nationality: Yup.string().when('countryCode', (val: string) => {
    if (val === 'CA') {
      return Yup.string().required('Nationality is required');
    } else {
      return Yup.string();
    }
  }),
  swiftCode: Yup.string()
    .required('Swift code is required')
    .max(11, '11 digits max')
    .matches(/(^[a-zA-Z0-9]+$|^(N\/A\b))+$/, 'These characters are not supported'),

  bankName: Yup.string()
    .required('Bank name is required')
    .min(2, 'Bank name should contain at least two letters')
    .matches(
      /^[a-zA-Z\u00C0-\u00FFŸŒ¢0-9]+([a-zA-Z\u00C0-\u00FFŸŒ¢0-9\s]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢0-9]+$/,
      'These characters are not supported'
    )
    .max(50, '50 characters max'),

  bankCountry: Yup.string().required('Bank country is required'),

  bankCity: Yup.string().when('bankCountry', (val: string) => {
    const condition = isCircleSupportedCountry(val);
    if (condition) {
      return Yup.string()
        .required('Bank city is required')
        .min(2, 'Bank city should contain at least two letters')
        .max(20, "Bank city shouldn't be longer than 20 characters")
        .matches(
          /^[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+([a-zA-Z0-9\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+$/,
          'These characters are not supported'
        );
    }
    return Yup.string();
  }),

  IBAN: Yup.string().when('bankCountry', (val: string) => {
    let maxLength = 30;

    if (isCircleSupportedCountry(val)) {
      maxLength = 32;
    } else if (val === 'CR') {
      maxLength = 11;
    }

    return Yup.string()
      .required('IBAN is required')
      .max(maxLength, `IBAN shouldn't be longer than ${maxLength} characters`)
      .matches(/(^[a-zA-Z0-9]+$|^(N\/A\b))+$/, 'These characters are not supported');
  }),

  bankAddress: Yup.string().when('bankCountry', (val: string) => {
    const condition = isCircleSupportedCountry(val);
    if (condition) {
      return Yup.string()
        .required('Bank Address is required')
        .matches(
          /^[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+([a-zA-Z0-9\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+$/,
          'These characters are not supported'
        )
        .max(30, '100 characters max');
    }
    return Yup.string()
      .required('Bank Address is required')
      .min(2, 'Bank name should contain at least two letters')
      .matches(
        /^[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+([a-zA-Z0-9\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+$/,
        'These characters are not supported'
      )
      .max(100, '100 characters max');
  }),
});

const validations = {
  ACH: validationSchemaAch,
  BANK_WIRE_DEFAULT: validationSchemaBankWireDefault,
  BANK_WIRE_BO: validationSchemaBankWireBolivia,
  PAYPAL: validationSchemaPayPal,
};

export default validations;

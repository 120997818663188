import blue from '@material-ui/core/colors/blue';

 const theme = {
    colors: {
        primaryText: '#fff'
    },
    palette: {
        primary: {
            contrastText: '#fff',
            main: blue[900]
        }
    },
    transparentBackground: {
        dark: 'rgba(0, 0, 0, 0.15)',
        light: 'rgba(255, 255, 255, 0.15)'
    }
};

export default theme

import { all, call, put, SagaReturnType, select, spawn, takeLatest } from 'redux-saga/effects';
import PrepaidCardApi, { PREPAID_CARD_STATUS } from '@root/services/api/requests/prepaidCard';
import {
  FETCH_PREPAID_CARD_PLAN,
  FETCH_PUSH_TO_CARD_DATA,
  FETCH_USER_DATA,
  FetchPrepaidCardPlanAction,
  SAVE_EDIT_DATA,
  SaveEditDataAction,
  SEND_TWILIO_CODE,
  SendTwilioCodeAction,
  VERIFY_TWILIO_CODE,
} from '../store/system/types';
import verifyPhone from '../services/api/requests/verifyPhone';
import {
  sendTwilioCode,
  setHomeConfirmationModal,
  setHomeEdit,
  setHomeFixedLoader,
  setHomeInitialData,
  setHomeLoader,
  setHomeStep,
  setHomeSuccessMessage,
  setHomeUserData,
  setPushToCardData,
} from '../store/system/actions';
import setNotification from '../utils/notifications';
import {
  selectHomeEdit,
  selectHomeStep,
  selectHomeUserData,
  selectUserData,
} from '../store/system/selectors';
import saveDLocalPreferences from '../services/api/requests/saveDLocalPreferences';
import getPayOutInfo, { IUserData } from '../services/api/requests/getPayOutInfo';
import getUserDetails from '../services/api/requests/getUserDetails';
import addPayOutInfo from '../services/api/requests/addPayOutInfo';
import { getIsTipaltyFlow, getSteps, normalizeLatinLetters } from '../utils/helpers';
import { getRedirectionToken } from '@root/services/api/requests/get-redirection-token';
import getPushToCardData from '@root/services/api/requests/getPushToCardData';
import apiClient from '@root/services/api/client';
import ApiUrls from '@root/services/api/apiUrls';

function* sendTwilioCodeWorker(action: SendTwilioCodeAction) {
  const canEdit: ReturnType<typeof selectHomeEdit> = yield select(selectHomeEdit);

  try {
    const data = action.payload;
    yield call(verifyPhone, data);
    yield put(setHomeConfirmationModal(true));
  } catch (e) {
    if (action.payload.phone) {
      if (e.includes('exceeded')) {
        setNotification('error', {
          message: e,
        });
        return;
      }

      if (e.includes('used before')) {
        setNotification('error', {
          message: e,
        });
      } else {
        setNotification('error', {
          message: 'You have an incorrect phone number. Please contact our support',
        });
      }
    }

    yield put(setHomeEdit(!canEdit));
    yield put(setHomeSuccessMessage(false));
    yield put(setHomeStep(0));
  }
}

function* verifyTwilioCodeWorker(action: SaveEditDataAction) {
  const canEdit: ReturnType<typeof selectHomeEdit> = yield select(selectHomeEdit);
  const activeStep: ReturnType<typeof selectHomeStep> = yield select(selectHomeStep);
  // const steps: ReturnType<typeof selectHomeStepList> = yield select(selectHomeStepList);
  const userData: ReturnType<typeof selectHomeUserData> = yield select(selectHomeUserData);

  const steps = getSteps(userData.method || '', userData.countryCode, userData.createdAt);

  const lastStep = steps?.length - 1;

  const { code, phone } = action.payload;

  try {
    // @ts-ignore
    const response: SagaReturnType<typeof verifyPhone> = yield call(verifyPhone, { code, phone });
    const data = response.data?.data || response.data;
    if (!data.valid && code) {
      setNotification('error', {
        message: 'Invalid verification code',
      });
      return;
    }

    setNotification('success', { message: 'Confirmed' });

    if (activeStep === lastStep) {
      yield put(setHomeConfirmationModal(false));
      yield put(setHomeEdit(!canEdit));
      yield put(setHomeSuccessMessage(false));
      yield put(setHomeStep(0));
    } else {
      yield put(setHomeEdit(false));
      yield put(setHomeConfirmationModal(false));
      yield put(setHomeStep(lastStep));
      yield put(setHomeSuccessMessage(true));
    }
  } catch (e) {
    console.warn('e', e);
    setNotification('error', { message: e });
  }
}

function* updatePrepaidCardPlan(action: FetchPrepaidCardPlanAction) {
  const userData: ReturnType<typeof selectHomeUserData> = yield select(selectHomeUserData);
  try {
    const prepaidCardPlan: SagaReturnType<typeof PrepaidCardApi.getPrepaidCardPlan> = yield call(
      PrepaidCardApi.getPrepaidCardPlan,
      import.meta.env.REACT_APP_PREPAID_CARD_PLAN_ID || 522,
      action.payload
    );
    yield put(
      setHomeUserData({ ...userData, prepaidCardPrice: prepaidCardPlan?.pricing?.price || 0 })
    );
  } catch (e) {
    console.error('e', e);
  }
}

function* getPrepaidCardBalance() {
  const userData: ReturnType<typeof selectHomeUserData> = yield select(selectHomeUserData);

  try {
    const response: SagaReturnType<typeof PrepaidCardApi.getPrepaidCardBalance> = yield call(
      PrepaidCardApi.getPrepaidCardBalance
    );

    const cards: SagaReturnType<typeof PrepaidCardApi.getMyPrepaidCards> = yield call(
      PrepaidCardApi.getMyPrepaidCards
    );

    if (response || cards) {
      const data = { ...userData, prepaidCardBalanceData: response, prepaidCards: cards };
      yield put(setHomeInitialData(data));
      yield put(setHomeUserData(data));
    }
  } catch (e) {
    console.error('e', e);
  }
}

function* fetchPushToCardDataWorker() {
  try {
    // @ts-ignore
    const response: SagaReturnType<typeof getPushToCardData> = yield call(getPushToCardData);
    yield put(setPushToCardData(response));
  } catch (e) {
    console.error('e', e);
  }
}

function* fetchUserDataWorker() {
  const userAuthData: ReturnType<typeof selectUserData> = yield select(selectUserData);
  const userData: ReturnType<typeof selectHomeUserData> = yield select(selectHomeUserData);

  try {
    const payOut: SagaReturnType<typeof getPayOutInfo> = yield call(getPayOutInfo);
    const details: SagaReturnType<typeof getUserDetails> = yield call(getUserDetails);

    //@ts-ignore
    // const pushToCardData: SagaReturnType<typeof getPushToCardData> = yield call(getPushToCardData);
    // const kyckWidgetUrl: SagaReturnType<typeof getKyckWidget> = yield call(getKyckWidget);
    const prepaidCard: SagaReturnType<typeof PrepaidCardApi.getPrepaidCard> = yield call(
      PrepaidCardApi.getPrepaidCard
    );
    const prepaidCardPlan: SagaReturnType<typeof PrepaidCardApi.getPrepaidCardPlan> = yield call(
      PrepaidCardApi.getPrepaidCardPlan,
      import.meta.env.REACT_APP_PREPAID_CARD_PLAN_ID || 522,
      details.country
    );
    const isPrepaidCardActive =
      prepaidCard &&
      [PREPAID_CARD_STATUS.INITIATED, PREPAID_CARD_STATUS.ACTIVE].includes(prepaidCard?.status);
    // const isPushToCardActive = payOut?.data?.data?.method === 'pushToCard';

    if (isPrepaidCardActive) {
      yield spawn(getPrepaidCardBalance);
    }

    const prepaidCardData = isPrepaidCardActive
      ? {
          firstName: prepaidCard.firstName,
          lastName: prepaidCard.lastName,
          email: prepaidCard.email,
          twilioPhone: prepaidCard.mobile,
          prepaidCardType: prepaidCard.cardType,
          prepaidPhysicalCardType: prepaidCard.cardOption || 'physicalAndVirtual',
          prepaidCardStatus: prepaidCard.status,
          countryCode: prepaidCard.countryCode,
          method: 'prepaidCard',
          setupFee: prepaidCard.setupFee,
        }
      : {
          setupFee: prepaidCard?.setupFee,
        };

    window.history.replaceState({}, document.title, window.location.pathname);

    const data = payOut?.data;

    const exigoData = {
      exigoFirstName: details.firstName,
      exigoLastName: details.lastName,
      exigoPhone: details.phone,
      countryCode: details.country,
    };

    const transformedData: IUserData = {
      exigoUserId: userAuthData?.userId,
      // kyckWidgetUrl,
      ...exigoData,
      ...prepaidCardData,
      prepaidCardData: prepaidCardData,
      prepaidCardPrice: prepaidCardPlan?.pricing?.price || 0,
      twilioPhone: userData.twilioPhone || prepaidCardData.twilioPhone || userData.phone,
      rankId: details.rankId,
    };

    if (data?.data) {
      if (!data.data.method) {
        yield put(setHomeUserData({ ...userData, ...transformedData }));
        yield put(setHomeLoader(false));
        return;
      }

      Object.keys(data.data)?.forEach((item) => {
        const value = data?.data?.[item];
        if (value) {
          transformedData[item] = value;
        }
      });

      const steps = getSteps(
        transformedData.method || '',
        transformedData.countryCode || '',
        transformedData.createdAt || ''
      );

      let step = steps?.length - 1;

      yield put(setHomeUserData(transformedData));
      yield put(setHomeEdit(false));
      yield put(setHomeInitialData(transformedData));
      yield put(setHomeStep(step));
      yield put(setHomeLoader(false));
      return;
    } else {
      yield put(setHomeLoader(false));
      yield put(setHomeInitialData({ ...userData, ...transformedData }));
      yield put(setHomeUserData({ ...userData, ...transformedData }));
    }
  } catch (e) {
    console.error('e', e);
  }
}

function* saveEditDataWorker(action: SaveEditDataAction) {
  const userData: ReturnType<typeof selectHomeUserData> = yield select(selectHomeUserData);
  const paymentData = action.payload;
  try {
    yield put(setHomeFixedLoader(true));

    const values: IUserData = { ...userData, ...paymentData };
    const requestData: IUserData = {
      phone: values.twilioPhone,
    } as IUserData;

    for (let item in values) {
      if (values.hasOwnProperty(item)) {
        const localFields = [
          'confirmTaxId',
          'confirmAccountNumber',
          'createdAt',
          'updatedAt',
        ].includes(item);

        const emptyFields = !String(values[item]).length;

        if (!localFields && !emptyFields) {
          requestData[item] = values[item];
        }
      }
    }

    if (requestData.method === 'prepaidCard') {
      const response: SagaReturnType<typeof PrepaidCardApi.initiatePrepaidCard> = yield call(
        // @ts-ignore
        PrepaidCardApi.initiatePrepaidCard,
        {
          countryCode: requestData.countryCode,
          firstName: requestData.firstName,
          lastName: requestData.lastName,
          email: requestData.email,
          mobile: requestData.twilioPhone,
          type: requestData.prepaidCardType,
          option: requestData.prepaidPhysicalCardType,
          status: userData.prepaidCardStatus,
        }
      );

      if (response && response?.status) {
        const redirectionResponse: SagaReturnType<typeof getRedirectionToken> =
          yield call(getRedirectionToken);
        if (redirectionResponse) {
          localStorage.setItem('showPrepaidCardMessage', 'true');
          window.location.href = `https://${redirectionResponse.redirectionUrl}/checkout?tempTok=${redirectionResponse.redirectionToken}&planId=${import.meta.env.REACT_APP_PREPAID_CARD_PLAN_ID || 522}&action=initial&quantity=${userData.prepaidCardQuantity}`;
          return;
        }
      }
    }

    const isTipaltyFlow = getIsTipaltyFlow({
      country: userData.countryCode,
      method: userData.method,
    });

    let payOut: SagaReturnType<typeof addPayOutInfo> | void;
    let responseError;
    let errorMessage;

    if (isTipaltyFlow || userData.method === 'PayPal') {
      yield call(apiClient.post, ApiUrls.updateUsingTipalti);
    } else {
      payOut = yield call(addPayOutInfo, requestData);
      const data = payOut?.data;

      responseError = data?.message === 'fail' || data?.statusCode === 400;

      if (responseError) {
        if (data?.data?.length) {
          // @ts-ignore
          errorMessage = normalizeLatinLetters(data?.data?.[0]?.message);
        }
        if (!data?.data && data?.message) {
          errorMessage = normalizeLatinLetters(data?.message);
        }
      }

      if (responseError) {
        // @ts-ignore
        errorMessage = normalizeLatinLetters(data?.data?.[0]?.message);
      }
    }

    if (responseError && errorMessage) {
      yield put(setHomeLoader(false));
      yield put(setHomeFixedLoader(false));
      setNotification('error', { message: errorMessage as string });
      yield put(setHomeLoader(false));
    } else {
      if (paymentData.dLocal) {
        const data = {
          ...paymentData.dLocal,
          countryCode: values.countryCode,
        };

        yield call(saveDLocalPreferences, data);
      }
      yield put(setHomeFixedLoader(false));
      yield put(setHomeInitialData(requestData));
      yield put(setHomeUserData(requestData));

      if (requestData.method !== 'pushToCard') {
        yield put(sendTwilioCode({ phone: requestData.twilioPhone as string }));
      }
    }
  } catch (e) {
    setNotification('error', { message: e });
    yield put(setHomeFixedLoader(false));
  }
}

export default function* homeSaga() {
  yield all([
    takeLatest(SEND_TWILIO_CODE, sendTwilioCodeWorker),
    takeLatest(VERIFY_TWILIO_CODE, verifyTwilioCodeWorker),
    takeLatest(FETCH_USER_DATA, fetchUserDataWorker),
    takeLatest(SAVE_EDIT_DATA, saveEditDataWorker),
    takeLatest(FETCH_PREPAID_CARD_PLAN, updatePrepaidCardPlan),
    takeLatest(FETCH_PUSH_TO_CARD_DATA, fetchPushToCardDataWorker),
  ]);
}

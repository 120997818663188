import React from 'react';
import { useSelector } from 'react-redux';
import { selectHomeStep } from '../../store/system/selectors';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';

export default function HomeStepper({ steps }: { steps: string[] }) {
  const activeStep = useSelector(selectHomeStep);
  // const steps = useSelector(selectHomeStepList) || [];
  
  return (<Stepper activeStep={activeStep} alternativeLabel>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>);
};

import * as Yup from 'yup';

import { IDLocalFields, IVDLocalDependency, IVDLocalBank, IFields } from './types';

const accountTypeOptions = {
  C: { value: 'C', label: 'Checking' },
  S: { value: 'S', label: 'Savings' },
  M: { value: 'M', label: 'Maestra' },
  V: { value: 'V', label: 'Vista' },
};

// @ts-ignore
const inputFields: IFields = {
  beneficiaryFirstName: {
    initialValue: '',
    id: 'beneficiaryFirstName',
    label: 'Beneficiary First Name',
    inputType: 'text',
  },
  beneficiaryLastName: {
    initialValue: '',
    id: 'beneficiaryLastName',
    label: 'Beneficiary Last Name',
    inputType: 'text',
  },
  documentId: { initialValue: '', id: 'documentId', label: 'Document Id', inputType: 'text' },
  bankBranch: { initialValue: '', id: 'bankBranch', label: 'Bank Branch', inputType: 'text' },
  bankAccount: {
    initialValue: '',
    id: 'bankAccount',
    label: 'Bank Account',
    inputType: 'text',
  },
  bankName: {
    initialValue: '',
    id: 'bankName',
    label: 'Bank Name',
    inputType: 'select',
    options: [{ value: '', label: 'Select your Bank' }],
  },

  bankCode: {
    initialValue: '',
    id: 'bankCode',
    label: 'Bank Name',
    inputType: 'select',
    options: [{ value: '', label: 'Select your Bank' }],
  },

  accountType: {
    options: [],
    initialValue: 'C',
    id: 'accountType',
    label: 'Account Type',
    inputType: 'select',
  },
  documentType: {
    initialValue: '',
    id: 'documentType',
    label: 'Document Type',
    inputType: 'text',
  },
  IBAN: { initialValue: '', id: 'IBAN', label: 'IBAN', inputType: 'text' },
  SWIFT: {
    initialValue: '',
    id: 'SWIFT',
    label: 'SWIFT Code',
    inputType: 'text',
    helperText: 'If you do not have a SWIFT code please enter N/A',
  },
  isCLABE: { initialValue: false, id: 'isCLABE', label: 'is CLABE', inputType: 'checkbox' },
  phone: { initialValue: '', id: 'phone', label: 'Phone', inputType: 'text' },
};

export const createInitialValues = (
  dLocalData: any,
  fieldsByCountry: any,
  banks: IVDLocalBank[]
) => {
  const values: any = {};

  getKeys(fieldsByCountry).forEach((item) => {
    if (dLocalData && dLocalData[item]) {
      if (item === 'bankCode') {
        const includes = banks.find((bank: IVDLocalBank) => bank.code === dLocalData.bankCode);
        includes ? (values[item] = dLocalData[item]) : (values.bankCode = '');
        return;
      }

      values[item] = dLocalData[item];
    } else {
      // @ts-ignore
      values[item] = inputFields[item]?.initialValue || '';
    }
  });
  return values;
};

const parseReqExpToOptions = (reqex: string) => {
  const isArray = Array.isArray(reqex);

  if (!isArray) {
    const wrongReqExpFormat = new RegExp('A-z|true*|false*|0-9', 'g').test(reqex);

    if (wrongReqExpFormat) {
      return null;
    }

    const stringWithoutReqExSymbols = reqex.replace(/[.,*+?^${}()[\]\\]/g, '');

    const canBeSplit = stringWithoutReqExSymbols.includes('|');

    const array: string[] = canBeSplit ? stringWithoutReqExSymbols.split('|') : [];

    if (!canBeSplit || !array?.length) {
      return null;
    }

    return array;
  }

  return null;
};

export const createFields = (fieldsByCountry: IDLocalFields, banks: IVDLocalBank[]) => {
  const fields = getKeys(fieldsByCountry);
  // @ts-ignore

  fields.forEach((key) => {
    const reqex = fieldsByCountry[key]?.regex;

    if (key === 'bankName') {
      // @ts-ignore
      return (inputFields.bankName.options = [
        // @ts-ignore
        ...inputFields.bankName.options,
        ...(banks ? banks.map(({ code, name }) => ({ value: name, label: name })) : []),
      ]);
    }

    if (key === 'bankCode') {
      // @ts-ignore
      return (inputFields.bankCode.options = [
        // @ts-ignore
        ...inputFields.bankCode.options,
        ...(banks ? banks.map(({ code, name }) => ({ value: code, label: name })) : []),
      ]);
    }
    if (key === 'accountType') {
      const options = (reqex as string)
        .replace(/[^\w]/g, '')
        .split('')
        .map((item) => accountTypeOptions[item as keyof typeof accountTypeOptions]);

      // @ts-ignore
      return (inputFields[key].options = options);
    }

    const options: '' | undefined | string[] | null =
      reqex && parseReqExpToOptions(reqex as string);

    if (options) {
      // @ts-ignore
      inputFields[key].options = options.map((item: string) => ({ value: item, label: item }));
      // @ts-ignore
      inputFields[key].inputType = 'select';
    }
  });

  return fields.map((item) => inputFields[item as keyof typeof inputFields]);
};

const createReqExp = (data: string | string[]): Array<RegExp> => {
  if (Array.isArray(data)) {
    return data.map((item) => new RegExp(item));
  }
  return [new RegExp(data)];
};
// eslint-disable-next-line
const getKeys = <T extends {}>(o: T): Array<keyof T> => <Array<keyof T>>Object.keys(o);

// !VALIDATIONS

export const createValidation = (bankFields: IDLocalFields) => {
  const fields: any = {};

  let keys = getKeys(bankFields);

  if (keys.includes('bankName') && keys.includes('bankCode')) {
    keys = keys.filter((item) => item !== 'bankName');
  }

  keys.forEach((key) => {
    let validator = Yup.string();

    const bankFieldData = bankFields[key] as IVDLocalDependency;

    if (!bankFieldData) {
      return;
    }

    const { mandatory, regex, errorMessage, depends } = bankFieldData;

    const message = errorMessage || 'Invalid value';

    if (mandatory) {
      validator = validator.required(message);
    }

    if (regex) {
      const regexpStrings = createReqExp(regex);

      validator = validator.test('is-valid', 'Invalid format', (value) => {
        return regexpStrings.some((item) => new RegExp(item).test(value as string));
      });
    }

    if (depends) {
      const key = depends[0].key;

      depends.forEach((item: any) => {
        validator = validator.when(key, (val: string | boolean) => {
          let dependsValidator = Yup.string();

          if (val === item.value || val === item.value?.toString()) {
            // console.warn('it', item);
            // console.warn('key', key);
            // console.warn('bankFieldData', bankFieldData);

            if (item.regex) {
              const regexpStrings = createReqExp(item.regex);

              return dependsValidator
                .test('is-valid', 'Invalid format', (value) => {
                  return value
                    ? regexpStrings.some((item) => new RegExp(item).test(value as string))
                    : true;
                })
                .required(message);
            }

            if (item.mandatory) {
              return dependsValidator.required(message);
            }

            if (!item.mandatory) {
              return Yup.string();
            }
          }
        });
      });
    }

    fields[key] = validator;
  });

  return fields;
};

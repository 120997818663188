import * as React from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IOption } from '../../types';
import useStyles from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IProps extends SelectProps {
  options: IOption[];
  errorMessage?: string;
}

const InputSelect: React.FC<IProps> = ({ errorMessage, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={`${props.id}-label`}>{props.label}</InputLabel>

      <Select
        labelId={`${props.id}-label`}
        variant="outlined"
        MenuProps={MenuProps}
        {...props}
        error={!!errorMessage}
      >
        {props.options.map(({ value, label, flag }) => (
          <MenuItem key={value} value={value}>
            {flag && <span className={classes.flag}>{flag}</span>}
            {label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default InputSelect;

import apiClient from '../client';
import ApiUrls from '../apiUrls';

export interface IKYCKUrlResponse {
  data: string;
}

const getIframeWidget = async (type: 'paypal' | 'pushToCard') => {
  
  const url = type === 'paypal' ? ApiUrls.paypalIframe : ApiUrls.payOutIframe;
  
  
  try {
    const response = await apiClient.post<IKYCKUrlResponse>(url);
    return response.data.data;
  } catch (e) {
    return null;
  }
};


export default getIframeWidget;

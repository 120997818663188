import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { entitiesReducer } from './entities/reducer';
import { systemReducer } from './system/reducer';
import { SystemState } from './system/types';
import { EntitiesState } from './entities/types';

export interface IState {
  router: RouterState;
  system: SystemState;
  entities: EntitiesState;
}

const createReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    system: systemReducer,
    entities: entitiesReducer,
  });

export default createReducer;

export type RootState = IState;

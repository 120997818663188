import React, { useEffect, useState } from 'react';

interface ICode {
  1: string;
  2: string;
  3: string;
  4: string;
}

// TODO: Types

const initialCodeValue = {
  1: '',
  2: '',
  3: '',
  4: '',
};

const usePhoneCodeHandler = () => {
  const [value, setValue] = useState<ICode>(initialCodeValue);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, position: number) => {
    // @ts-ignore
    const isNumber = /^-?[\d.]+(?:e-?\d+)?$/.test(Number(e.target.value));
    if (!isNumber) {
      return;
    }

    const nextValue: ICode = { ...value };

    // @ts-ignore
    const backSpace = e.keyCode === 8;

    // @ts-ignore
    nextValue[position] = e.target.value;

    setValue(nextValue);

    // @ts-ignore
    const nextPosition = position + 1;

    if (
      // @ts-ignore
      value[nextPosition.toString()] &&
      // @ts-ignore
      value[nextPosition.toString()].length &&
      !(!e.target.value.length && backSpace)
    ) {
      return;
    }

    const nextInput = document.getElementById(`input#${Number(position) + 1}`);
    const prevInput = document.getElementById(`input#${Number(position) - 1}`);

    if (nextInput && e.target.value.length) {
      nextInput.focus();
      return;
    }
    if (!e.target.value.length && backSpace && prevInput) {
      prevInput.focus();
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, position: number) => {
    const backSpace = e.keyCode === 8;
    const arrowLeft = e.keyCode === 37;
    const arrowRight = e.keyCode === 39;
    // @ts-ignore
    const caretStart = e.target.selectionStart === 0;
    // @ts-ignore
    const inputValue = e.target.value;

    const nextInput = document.getElementById(`input#${Number(position) + 1}`);
    const prevInput = document.getElementById(`input#${Number(position) - 1}`);

    if (!inputValue && backSpace && prevInput) {
      prevInput.focus();
    }

    if (arrowLeft && caretStart && prevInput) {
      prevInput.focus();
    }

    if (arrowRight && !caretStart && nextInput) {
      nextInput.focus();
    }
  };

  const onFocus = (position: number) => {
    const textFiled = document.getElementById(`input#${position}`);
    // @ts-ignore
    textFiled?.addEventListener('focus', () => {
      // eslint-disable-next-line react/no-this-in-sfc, no-unused-expressions
      // @ts-ignore
      this?.select();
    });
  };

  useEffect(() => {
    return () => setValue(initialCodeValue);
  }, []);

  return {
    onFocus,
    value,
    disabled: Object.values(value).join('').length !== 4,
    onChange,
    onKeyPress,
  };
};

export default usePhoneCodeHandler;

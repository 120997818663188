import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Form from './form';
import getDLocalBankPreferences from '../../services/api/requests/getDLocalBankPreferences';
import { selectHomeUserData } from '../../store/system/selectors';
import { IDLocalInputFields } from './types';
import { saveEditData } from '../../store/system/actions';
import { createInitialValues, createValidation, createFields } from './dLocal';
import { useStyles } from '../../pages/Payout/styles';

interface IProps {
  renderButtons?(a: any): React.ReactNode;
}

export default function PaymentMethodDLocal(props: IProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(selectHomeUserData);
  const { countryCode, dLocal: dLocalData } = user;
  const [bankData, setBankData] = useState<any>(null);

  const fetchBankPreferences = async () => {
    try {
      const response = await getDLocalBankPreferences(countryCode as string);

      const bankName = response.data.data.fields?.bankName;
      const bankCode = response.data.data.fields?.bankCode;
      if (bankName && bankCode) {
        delete response.data.data.fields?.bankName;
      }

      setBankData(response.data.data);
    } catch (e) {
      console.warn('Error during fetching bank data');
    }
  };

  const handleSubmit = (values: IDLocalInputFields) => {
    const data = {};

    //TODO: move to utils

    Object.keys(values).forEach((i) => {
      // @ts-ignore
      if ((values[i] && values[i].length) || typeof values[i] === 'boolean') {
        // @ts-ignore
        data[i] = values[i];
      }
    });

    dispatch(saveEditData({ dLocal: data }));
  };

  useEffect(() => {
    if (countryCode) {
      fetchBankPreferences().catch(console.log);
    }
    return () => setBankData(null);
    // eslint-disable-next-line
  }, [countryCode]);

  useEffect(() => {
    if (countryCode) {
      fetchBankPreferences();
    }
    return () => setBankData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line
  }, [countryCode]);

  if (!bankData) {
    return (
      <div className={classes.fixedLoader}>
        <CircularProgress disableShrink />
      </div>
    );
  }

  const formData = {
    validationRules: bankData && createValidation(bankData.fields),
    fields: bankData && createFields(bankData.fields, bankData.banks),
    initialValues: bankData && createInitialValues(dLocalData, bankData.fields, bankData.banks),
    onSubmit: handleSubmit,
    bankCodes: bankData?.banks,
  };

  return <Form formData={formData} {...props} />;
}

import React, { FC, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';

const DocumentIframeModal: FC<{ url: string; close: () => void }> = ({ url, close }) => {
  const [isFullSize] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const classes = useStyles({ isFullSize });

  return (
    <Modal
      className={classes.modal}
      open={!!url}
      onClose={close}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={!!url}>
        <div className={classes.wrapper}>
          <div className={classes.iframe}>
            <iframe
              title={'if'}
              onLoad={() => setLoaded(true)}
              width={'100%'}
              height={'100%'}
              src={url}
              frameBorder="0"
              style={{ position: 'relative' }}
            />

            {!loaded && (
              <div className={classes.fixedLoader}>
                <CircularProgress disableShrink />
              </div>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
    },

    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    iframe: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: ({ isFullSize }: { isFullSize: boolean }) => (isFullSize ? '90vw' : '60vw'),
      height: '90vh',
    },

    fixedLoader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.52)',
      zIndex: 100,
    },

    widthToggle: {
      width: '80px',
      height: '80px',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: 99,
    },
  })
);

export default DocumentIframeModal;

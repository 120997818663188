import { ActionTypes, EntitiesState, SAVE_REGIONS } from './types';

import Regions from '../../utils/regions';

const initialState: EntitiesState = {
  regions: Regions,
};

export function entitiesReducer(state = initialState, action: ActionTypes): EntitiesState {
  switch (action.type) {
    case SAVE_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };

    default:
      return state;
  }
}

import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
	taxInvoicePaperHeader: {
		gap: '10px 20px',
		flexWrap: 'wrap'
	},

	taxInvoiceDateInputWrapper: {
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap'
		}
	},

	taxInvoiceDateInput: {
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap'
		}
	},

	taxInvoiceDataLabelWrapper: {
		marginRight: '20px',
		width: 'auto',
		
		[theme.breakpoints.down('xs')]: {
			marginBottom: '10px',
			width: '100%',
		}
	},

	taxInvoiceDataLabel: {
		color: 'rgba(51, 51, 51, 0.54)',
    marginRight: '10px',
		width: 'auto',

		[theme.breakpoints.down('xs')]: {
			width: '50%'
		}
	},

	taxInvoiceTableHeaderCell: {
		[theme.breakpoints.down('xs')]: {
			width: '50%'
		}
	}
}));

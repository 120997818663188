import apiClient from '@root/services/api/client';

export type MaintenanceResponse = string[]

export const getMaintenance = async (): Promise<string[] | undefined> => {
  try {
    const response = await apiClient.get('/shop/instance-types/in-maintenance');
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import App from './App';
import createReducer from './store';
import { unregister } from './serviceWorker';
import rootSaga from './sagas';
import './index.css';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory();

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const middlewares = [routerMiddleware(history), sagaMiddleware];

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = createReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(rootReducer, );

let store = createStore(persistedReducer, enhancer);

// then run the saga
// @ts-ignore
sagaMiddleware.run(rootSaga);

const Main = () => (
  <Provider store={store}>
    <App history={history} />
  </Provider>
);

ReactDOM.render(<Main />, document.getElementById('root') as HTMLElement);

unregister();

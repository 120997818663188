import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectLoading } from '../../store/system/selectors';
import { Button, Grid, Link, Paper } from '@material-ui/core';
import { useStyles } from './styles';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { startDefaultLogin } from '../../store/system/actions';
import pageLinks from '../../utils/pageLinks';
import { inputCreator } from '../../components/BaseForm';
import FormLayout from '../../components/FormLayout';
import { INSTANCE_TYPES } from '../../enums/instance-types';

interface IValues {
  username: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.mixed().required('Password is required'),
});

const initialValues: IValues = {
  username: '',
  password: '',
};

export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showLoader = useSelector(selectLoading);

  const onFormSubmit = async (values: IValues) => {
    const { username, password } = values;
    const data = {
      username,
      password,
      instanceType: INSTANCE_TYPES.BE_PAY,
    };
    const isValues = username && password;
    if (isValues) {
      dispatch(startDefaultLogin(data));
    }
  };

  const fk: FormikProps<IValues> = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: onFormSubmit,
  });

  const openPage = () => {
    window.open('https://www.bebackoffice.com/forgotpassword', '_self');
  };

  const createInput = inputCreator<IValues>(fk);
  const usernameInput = createInput({
    id: 'username',
    label: 'Username',
    md: 12,
  });
  const passwordInput = createInput({
    id: 'password',
    type: 'password',
    label: 'Password',
    md: 12,
  });

  if (showLoader) {
    return (
      <div className={classes.fixedLoader}>
        <CircularProgress disableShrink />
      </div>
    );
  }

  return (
    <FormLayout>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.grid}
      >
        <div className={classes.inputContainer}>
          <Grid container xs={10} spacing={2}>
            {usernameInput}
            {passwordInput}
            <Grid container alignContent="space-between" className={classes.links}>
              <Grid item xs>
                <Link href={'#'} variant="body1" onClick={openPage}>
                  Reset password
                </Link>
              </Grid>
              <Grid>
                <Link href={pageLinks.forgotUsername} variant="body1" onClick={openPage}>
                  Remind username
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => fk.handleSubmit()}
              className={classes.submit}
            >
              Submit
            </Button>
          </Grid>
        </div>
      </Grid>
    </FormLayout>
  );
}

import { countries, Country } from 'countries-list';

export const isValidCountryCode = (code: string) => {
  return typeof code === 'string' && countries.hasOwnProperty(code.toUpperCase());
};

export const getCountryData = (code: string) => {
  if (!isValidCountryCode(code)) {
    return null;
  }
  return countries[code.toUpperCase() as keyof typeof countries];
};

export const getCountryPhoneCode = (countryCode: string): string[] | [] => {
  if (!isValidCountryCode(countryCode)) {
    return [];
  }
  const country = getCountryData(countryCode);
  return country?.phone.split(',')?.map((code) => `+${code}`) || [];
};

export const findC = (code: string) => {
  if (!isValidCountryCode(code)) {
    return null;
  }
  return countries[code.toUpperCase() as keyof typeof countries];
};

export const divideCodeAndPhoneNumber = (
  phoneParam: string
): { phoneCode: string; phone: string } => {
  try {
    const countryWithPhoneCode = Object.values(countries).find((country) =>
      country.phone.split(',').some((item) => phoneParam.startsWith(`+${item}`))
    );

    const phoneCode = countryWithPhoneCode?.phone
      .split(',')
      .find((item) => phoneParam.startsWith(`+${item}`)) as string;

    const phone = phoneParam.replace(`+${phoneCode}`, '');

    return { phoneCode: `+${phoneCode}`, phone };
  } catch (e) {
    return { phoneCode: '', phone: '' };
  }
};

export const getInitialCountryCode = (countryCodeParam: string, phone: string) => {
  const country = getCountryData(countryCodeParam);

  if (country && country.phone.includes(',')) {
    const countryCodes = country.phone.split(',').map((code) => `+${code}`);

    if (!phone) {
      return countryCodes[0];
    }

    countryCodes.map((code) => {
      const len = code.length;
      const cutCodeFromThePhoneNumber = phone.slice(0, len);
      if (code === cutCodeFromThePhoneNumber) {
        return code;
      }

      return null;
    });
  }

  return (country as Country).phone;
};

export const getInitialPhoneNumber = (countryCodeParam: string, phone: string) => {
  if (!phone) {
    return '';
  }

  const country = getCountryData(countryCodeParam);
  if (country && country.phone.includes(',')) {
    const countryCode = getInitialCountryCode(countryCodeParam, phone);
    return phone && phone.replace(countryCode, '');
  }

  return countryCodeParam.length && phone
    ? phone.replace(`+${(getCountryData(countryCodeParam) || { phone: '' }).phone}`, '')
    : '';
};

import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormikProps, useFormik } from 'formik';
import useEmailValidator from '../../hooks/emailValidatior';
import * as dateFns from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CountrySelect from '../../components/CountrySelect';
import VALIDATION from './validation';
import { inputCreator, selectCreator } from '../../components/BaseForm';
import { useStyles } from './styles';
import useRoutingNumberValidator from '../../hooks/useRoutingValidatior';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHomeEdit,
  selectHomeInitialData,
  selectHomeUserData,
} from '../../store/system/selectors';
import CustomButtons from '../../components/CustomButtons';
import { saveEditData } from '../../store/system/actions';
import InputSelect from '@root/components/InputSelect';
import { nationalities } from '@root/constants/nationalities';

const accountTypeOptions = [
  {
    value: 'checking',
    label: 'Checking',
  },
  {
    value: 'saving',
    label: 'Saving',
  },
];

const methodsData: Record<string, { value: string }> = {
  PayPal: {
    value: 'paypalEmail',
  },
  payoneer: {
    value: 'payoneerEmail',
  },
};

const currencyOptions = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
];

const createMaxDate = () => new Date(new Date().setFullYear(new Date().getFullYear() - 18));

interface IValues {
  countryCode: string;
  method: string;
  paypalEmail: string;
  payoneerEmail: string;
  bankName: string;
  accountType: 'checking' | 'saving' | '';
  routingNumber: string;
  finInstitutionNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  bankAddress: string;
  bankCountry: string;
  bankCity: string;
  accountHolderName: string;
  IBAN: string;
  swiftCode: string;
  countryOfBirth: string;
  sortCode: string;
  dateOfBirth: string | Date;
  currency: 'USD' | 'EUR';
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  placeOfBirth: string;
  nationality: string;
}

const initialValues: IValues = {
  paypalEmail: '',
  bankAddress: '',
  accountHolderName: '',
  swiftCode: '',
  countryOfBirth: '',
  dateOfBirth: createMaxDate(),
  accountType: 'checking',
  routingNumber: '',
  finInstitutionNumber: '',
  bankName: '',
  accountNumber: '',
  countryCode: '',
  method: '',
  confirmAccountNumber: '',
  bankCountry: '',
  bankCity: '',
  currency: 'USD',
  IBAN: '',
  sortCode: '',
  beneficiaryFirstName: '',
  beneficiaryLastName: '',
  payoneerEmail: '',
  placeOfBirth: '',
  nationality: '',
};

const validationMethodSchema = {
  PayPal: VALIDATION.PAYPAL,
  ACH: VALIDATION.ACH,
  bankWire: VALIDATION.BANK_WIRE_DEFAULT,
  bankWireBO: VALIDATION.BANK_WIRE_BO,
};

const getValidation = (method: keyof typeof validationMethodSchema, country: string) => {
  return (
    validationMethodSchema[(method + country) as keyof typeof validationMethodSchema] ||
    validationMethodSchema[method]
  );
};

export default function PaymentMethodStep() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const canEdit = useSelector(selectHomeEdit);
  const user = useSelector(selectHomeUserData);
  const initialData = useSelector(selectHomeInitialData);
  const { emailErrors, validateEmail } = useEmailValidator();
  const { routingNumberError, validateRoutingNumber } = useRoutingNumberValidator();

  const {
    currency,
    paypalEmail,
    payoneerEmail,
    bankAddress,
    accountHolderName,
    swiftCode,
    countryOfBirth,
    dateOfBirth,
    accountType,
    routingNumber,
    bankName,
    accountNumber,
    countryCode,
    method,
    bankCountry,
    bankCity,
    IBAN,
    beneficiaryFirstName,
    beneficiaryLastName,
    finInstitutionNumber,
    exigoFirstName,
    exigoLastName,
    nationality,
  } = user;

  const userData = {
    currency,
    paypalEmail,
    payoneerEmail,
    bankAddress,
    accountHolderName,
    swiftCode,
    countryOfBirth,
    dateOfBirth,
    accountType,
    routingNumber,
    bankName: initialData.method !== user.method ? '' : bankName,
    accountNumber: initialData.method !== user.method ? '' : accountNumber,
    countryCode,
    method,
    bankCountry,
    bankCity,
    IBAN,
    beneficiaryFirstName: beneficiaryFirstName || exigoFirstName,
    beneficiaryLastName: beneficiaryLastName || exigoLastName,
    finInstitutionNumber,
    nationality,
  };

  const fk: FormikProps<IValues> = useFormik({
    initialValues: { ...initialValues, ...userData },
    validationSchema: canEdit && getValidation(userData.method, userData.countryCode),
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values: IValues) => {
      if (!fk.isValid) {
        return;
      }

      const {
        currency,
        paypalEmail,
        payoneerEmail,
        bankAddress,
        accountHolderName,
        swiftCode,
        countryOfBirth,
        dateOfBirth,
        accountType,
        routingNumber,
        finInstitutionNumber,
        bankName,
        accountNumber,
        bankCountry,
        bankCity,
        IBAN,
        sortCode,
        beneficiaryFirstName,
        beneficiaryLastName,
        placeOfBirth,
        nationality,
      } = values;

      dispatch(
        saveEditData({
          currency,
          paypalEmail,
          bankAddress,
          accountHolderName,
          finInstitutionNumber,
          swiftCode,
          countryOfBirth,
          dateOfBirth: dateOfBirth
            ? dateFns.formatISO(new Date(fk.values.dateOfBirth), { representation: 'date' }) +
              'T00:00:00.000Z'
            : dateOfBirth,
          accountType,
          routingNumber,
          bankName,
          accountNumber,
          bankCountry,
          bankCity,
          IBAN,
          sortCode: sortCode ? sortCode.toString() : sortCode,
          beneficiaryFirstName,
          beneficiaryLastName,
          payoneerEmail,
          placeOfBirth: placeOfBirth || undefined,
          nationality: nationality || undefined,
        })
      );
    },
  });

  const validateEmailAddress = (e: React.FocusEvent<HTMLInputElement>) => {
    fk.handleBlur(e);
    const { value, name } = e.target;
    validateEmail({ value, name });
  };

  const onBlurRoutingNumber = async (e: React.FocusEvent<HTMLInputElement>) => {
    fk.handleBlur(e);
    const { value } = e.target;
    validateRoutingNumber(value as string);
  };

  const showGBBankWireAdditionalFields = userData.countryCode === 'GB';

  const paymentText =
    fk.values.method === 'PayPal'
      ? 'PayPal'
      : fk.values.method === 'payoneer'
        ? 'Payoneer'
        : 'BANK Account';

  const maxDate = createMaxDate();

  if (userData.method === 'bankWire') {
    if (
      fk.values.dateOfBirth &&
      !fk.errors.dateOfBirth &&
      dateFns.isAfter(new Date(fk.values.dateOfBirth) as Date, maxDate)
    ) {
      fk.setFieldError('dateOfBirth', 'Date should not be after maximal date');
    } else if (
      fk.errors.dateOfBirth?.length &&
      dateFns.isValid(fk.values.dateOfBirth) &&
      !dateFns.isAfter(new Date(fk.values.dateOfBirth) as Date, maxDate)
    ) {
      fk.setFieldError('dateOfBirth', '');
      fk.validateField('dateOfBirth');
    }
  }

  const isDisabled = !canEdit;
  const createInput = inputCreator<IValues>(fk, isDisabled);
  const createSelect = selectCreator<IValues>(fk, isDisabled);
  const isBolivia = userData.countryCode === 'BO';
  const isCanada = userData.countryCode === 'CA';
  const payPalOrPayoneerField = methodsData[fk.values.method]?.value as keyof IValues;

  return (
    <form className={classes.form} onSubmit={fk.handleSubmit}>
      <Grid item xs={12} className={classes.paymentTitle}>
        <Typography variant="h6" component="h6">
          {paymentText}
        </Typography>
      </Grid>

      {/*//? BANK WIRE BOLIVIA */}
      <Grid container spacing={2}>
        {fk.values.method === 'bankWire' && isBolivia && (
          <>
            {createInput({
              id: 'beneficiaryFirstName',
              label: 'Beneficiary First name',
              isRequired: true,
            })}
            {createInput({
              id: 'beneficiaryLastName',
              label: 'Beneficiary Last name',
              isRequired: true,
            })}

            {createInput({ id: 'bankName', label: 'Bank Name' })}
            {createInput({ id: 'bankAddress', label: 'Bank Address', isRequired: true })}
            {createInput({ id: 'bankCity', label: 'Bank City', isRequired: true })}

            {createInput({
              id: 'accountNumber',
              label: `Account number`,
              isRequired: true,
            })}

            {createInput({ id: 'IBAN', label: 'IBAN' })}
            {createInput({
              id: 'swiftCode',
              label: 'Swift code',
              explanationText: 'If you do not have a SWIFT code please enter N/A',
            })}
          </>
        )}

        {fk.values.method === 'bankWire' && !isBolivia && (
          <>
            <Grid item xs={12} md={6}>
              <CountrySelect
                reinitialize={false}
                disabled={false}
                label={'Bank Country*'}
                id={'bankCountry'}
                value={fk.values.bankCountry}
                onChange={(val) => {
                  fk.setFieldValue('bankCountry', val, true);
                  if (val.length) {
                    delete fk.errors.bankCountry;
                  }
                }}
                error={!!(fk.errors.bankCountry && fk.touched.bankCountry)}
                helperText={fk.touched.bankCountry ? fk.errors.bankCountry : ''}
              />
            </Grid>
            {createInput({
              id: 'accountHolderName',
              label: 'Name on Account ',
              md: 6,
              isRequired: true,
            })}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  id={'dateOfBirth'}
                  fullWidth
                  required
                  label="Date of Birth"
                  format="dd/MM/yyyy"
                  placeholder={'dd/mm/yyyy'}
                  disabled={isDisabled}
                  maxDate={canEdit && fk.values.dateOfBirth ? maxDate : undefined}
                  value={fk.values.dateOfBirth ? fk.values.dateOfBirth : null}
                  onChange={(e) => fk.setFieldValue('dateOfBirth', e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputProps={(props: any) => (
                    <TextField
                      {...props}
                      label="Choose a country"
                      variant="outlined"
                      fullWidth
                      required
                      error={!!(fk.errors.dateOfBirth && fk.touched.dateOfBirth)}
                      helperText={fk.touched.dateOfBirth && fk.errors.dateOfBirth}
                      inputProps={{
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12} sm={6}>
              <CountrySelect
                id={'countryOfBirth'}
                value={fk.values.countryOfBirth}
                reinitialize={false}
                onChange={(val) => {
                  fk.setFieldValue('countryOfBirth', val, true);
                  if (val.length) {
                    delete fk.errors.countryOfBirth;
                  }
                }}
                disabled={isDisabled}
                label={'Choose a country of birth'}
                onBlur={() => fk.setFieldTouched('countryOfBirth', true)}
                error={!!(fk.touched.countryOfBirth && !fk.values.countryOfBirth)}
                helperText={fk.touched.countryOfBirth ? fk.errors.countryOfBirth || '' : ''}
                required
              />
            </Grid>

            {isCanada && (
              <Grid item xs={12} md={6}>
                <InputSelect
                  id="nationality"
                  name="nationality"
                  label="Nationality*"
                  required
                  value={fk.values.nationality}
                  options={nationalities.map((value) => ({ value, label: value }))}
                  onChange={(e) => {
                    fk.setFieldValue('nationality', e.target.value);
                  }}
                  onBlur={() => fk.setFieldTouched('nationality', true)}
                  inputProps={{ maxLength: 9 }}
                  variant="outlined"
                  errorMessage={fk.errors.nationality}
                />
              </Grid>
            )}

            {createInput({
              id: 'accountNumber',
              label: `Account number`,
              isRequired: true,
            })}

            {createInput({
              id: 'IBAN',
              label: 'IBAN',
              explanationText: 'if you do not have IBAN please enter N/A',
              isRequired: true,
            })}

            {createInput({
              id: 'swiftCode',
              label: 'Swift code',
              explanationText: 'If you do not have a SWIFT code please enter N/A',
              isRequired: true,
            })}

            {userData.countryCode === 'CA' &&
              createInput({ id: 'finInstitutionNumber', label: 'Financial Institution Number' })}

            {createInput({
              id: 'routingNumber',
              onBlur: onBlurRoutingNumber,
              errorText: routingNumberError,
              label: userData.countryCode === 'CA' ? 'Transit number' : 'Routing Number',
            })}

            {createInput({ id: 'bankName', label: 'Bank Name', isRequired: true })}
            {createInput({ id: 'bankAddress', label: 'Bank Address', isRequired: true })}
            {createInput({ id: 'bankCity', label: 'Bank City', isRequired: true })}
            {showGBBankWireAdditionalFields &&
              createInput({ id: 'sortCode', label: 'Sort Code', type: 'string', isRequired: true })}
          </>
        )}

        {fk.values.method === 'ACH' && (
          <>
            {createInput({ id: 'bankName', label: 'Bank Name' })}

            {userData.countryCode === 'CA' &&
              createInput({ id: 'finInstitutionNumber', label: 'Financial Institution Number' })}

            {createSelect({
              label: 'Account Type',
              id: 'accountType',
              options: accountTypeOptions,
              disabled: isDisabled,
            })}

            {createInput({
              id: 'routingNumber',
              onBlur: onBlurRoutingNumber,
              errorText: routingNumberError,
              label: userData.countryCode === 'CA' ? 'Transit number' : 'Routing Number',
            })}

            {createInput({ id: 'accountNumber', label: 'Account Number' })}
            {createInput({ id: 'confirmAccountNumber', label: 'Confirm Account Number' })}

            {createInput({
              id: 'IBAN',
              label: 'IBAN',
              explanationText: 'if you do not have IBAN please enter N/A',
              isRequired: true,
            })}

            {createInput({
              id: 'swiftCode',
              label: 'Swift code',
              explanationText: 'If you do not have a SWIFT code please enter N/A',
              isRequired: true,
            })}
          </>
        )}

        {['PayPal', 'payoneer'].includes(fk.values.method) && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id={payPalOrPayoneerField}
                label="Email"
                name={payPalOrPayoneerField}
                autoComplete="new-password"
                disabled={isDisabled}
                error={
                  !!(
                    (fk.errors[payPalOrPayoneerField] && fk.touched[payPalOrPayoneerField]) ||
                    emailErrors?.[payPalOrPayoneerField]
                  )
                }
                onBlur={validateEmailAddress}
                helperText={
                  fk.touched[payPalOrPayoneerField] &&
                  (fk.errors[payPalOrPayoneerField] || emailErrors?.[payPalOrPayoneerField])
                }
                value={fk.values[payPalOrPayoneerField]}
                onChange={fk.handleChange}
              />
            </Grid>

            {createSelect({
              label: 'Currency',
              id: 'currency',
              options: currencyOptions,
              disabled: isDisabled,
            })}
          </>
        )}
      </Grid>

      <div className={classes.paymentTitle}>
        {canEdit ? (
          <CustomButtons
            resetForm={() => fk.handleReset()}
            isDisabled={!fk.isValid || emailErrors || routingNumberError}
          />
        ) : (
          <div />
        )}
      </div>
    </form>
  );
}

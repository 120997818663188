import { call, fork, put, race, SagaReturnType, take } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { END, eventChannel } from 'redux-saga';
import { Socket } from 'socket.io-client';
import { SocketEventPayloads } from './event-payloads';
import { SocketEventTypes } from './event-types';
import { SocketEvents } from './events';
import { createSocket } from './socket';
import { FETCH_USER_DATA, LOGOUT } from '@root/store/system/types';
import { setHomeStep, setWaitingForWebhook } from '@root/store/system/actions';


type SocketChannel = SagaReturnType<typeof socketChannel>;

type SocketAction = SocketEvents.TipaltyUpdated;

// eslint-disable-next-line
function* socketChannel(socket: Socket) {
  return eventChannel<SocketAction>((emit) => {
    
    
    const errorHandler = () => emit(END);
    
    const currencyUpdatedHandler = (payload: SocketEventPayloads.TipaltyUpdated) => {
      emit({ type: SocketEventTypes.TipaltyUpdated, payload });
    };
    
    
    socket.on(SocketEventTypes.TipaltyUpdated, currencyUpdatedHandler);
    socket.on('connect_error', errorHandler);
    socket.on('disconnect', errorHandler);
    
    return () => {
      socket.off(SocketEventTypes.TipaltyUpdated, currencyUpdatedHandler);
      socket.off('connect_error', errorHandler);
      socket.off('disconnect', errorHandler);
    };
  });
}

function* socketChannelHandler(socket: Socket, channel: SocketChannel) {
  while (true) {
    const { socketAction, shouldCancel }: { socketAction?: SocketAction; shouldCancel?: PayloadAction } = yield race({
      socketAction: take(channel),
      shouldCancel: take([LOGOUT]),
    });
    
    if (socketAction) {
      yield socketActionHandler(socketAction);
    }
    
    if (shouldCancel) {
      channel.close();
      socket.disconnect();
    }
  }
}

function* socketActionHandler(action: SocketAction) {
  switch (action.type) {
    case SocketEventTypes.TipaltyUpdated: {
      try {
        yield put(setHomeStep(2));
        yield put({ type: FETCH_USER_DATA });
        yield put(setWaitingForWebhook(false));
        break;
      } catch (e) {
        console.log('SOKET CURRENCY ERROR', action);
        break;
      }
    }
  }
}

export function* socketSaga() {
  while (true) {
    yield take(FETCH_USER_DATA);
    
    const socket = createSocket();
    const channel: SocketChannel = yield call(socketChannel, socket);
    
    yield fork(socketChannelHandler, socket, channel);
  }
}

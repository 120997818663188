import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import usePhoneCodeHandler from '../../hooks/phoneVerification';
import useDebounce from '../../hooks/useDebounce';
import { setHomeConfirmationModal, verifyTwilioCode } from '../../store/system/actions';
import { selectHomeConfirmationModal, selectHomeUserData } from '../../store/system/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    paperModal: {
      padding: theme.spacing(2),
      width: '100%',
      maxWidth: 400,
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      backgroundColor: '#fff',
      margin: '0 20px',
    },

    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },

    wrapper: {
      alignItems: 'center',
      position: 'relative',
      maxWidth: 360,
      width: '90%',
      background: '#fff',
      margin: '30px auto',
    },

    title: {
      fontSize: 40,
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#421c60',
      marginBottom: 30,
    },

    textInfo: {
      fontSize: 20,
      fontWeight: 500,
      color: '#363636',
      marginBottom: 30,
      textAlign: 'center',
    },

    long: {
      maxWidth: 290,
      margin: '0 auto 55px',
    },

    form: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 0 55px 0',
    },

    input: {
      display: 'block',
      width: 58,
      height: 60,
      borderRadius: 4,
      boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.06)',
      border: 'solid 1px #e3d4ef',
      backgroundColor: '#fff',
      margin: '0 7px',
      textAlign: 'center',
      fontWeight: 500,
      color: '#5d01a2',
      fontSize: 20,
      transition: 'all 0.3s',
      '&:focus': {
        border: 'solid 1px #5d01a2',
      },
    },

    filled: {
      backgroundColor: 'rgba(184, 169, 196, 0.06)',
    },

    cancel: {
      marginTop: 10,
    },
  })
);

export default function ConfirmPhoneModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(selectHomeConfirmationModal);
  const userData = useSelector(selectHomeUserData);
  const phone: string = userData?.twilioPhone || '';
  const { onFocus, value, disabled, onChange, onKeyPress } = usePhoneCodeHandler();
  const [submitCounter, setCounter] = useState<number>(0);

  const debouncedValue = useDebounce<number>(submitCounter, 500);

  const close = () => dispatch(setHomeConfirmationModal(false));

  const verifyCode = () => {
    const code = Object.values(value).join('');

    if (code.length === 4) {
      dispatch(verifyTwilioCode({ phone, code }));
    }
  };

  const startVerification = (e: React.FormEvent) => {
    e?.preventDefault();
    setCounter((val) => val + 1);
  };

  const formattedPhone = phone.replace(
    phone.substring(3, phone.length - 4),
    phone
      .substring(3, phone.length - 4)
      .split('')
      .reduce((acc, val) => acc + 'X', '')
  );

  useEffect(() => {
    verifyCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, value]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paperModal}>
          <div className="container">
            <>
              <div className={classes.wrapper}>
                <div className={classes.title}>Verification</div>

                <div className={classes.textInfo}>Enter your verification code.</div>

                <div className={clsx(classes.textInfo, classes.long)}>
                  Input the code we sent to {formattedPhone} to edit your payment preferences
                </div>

                <Grid item xs={12}>
                  <form className={classes.form} onSubmit={startVerification}>
                    {[1, 2, 3, 4].map((item) => (
                      <input
                        key={item}
                        name={item.toString()}
                        id={`input#${item}`}
                        type="tel"
                        maxLength={1}
                        // @ts-ignore
                        className={clsx(classes.input, value[item].length ? classes.filled : '')}
                        onChange={(e) => onChange(e, item)}
                        onKeyDown={(e) => onKeyPress(e, item)}
                        onFocus={() => onFocus(item)}
                        // @ts-ignore
                        value={value[item]}
                      />
                    ))}
                    <input type="submit" hidden />
                  </form>
                </Grid>
              </div>

              <Button
                fullWidth
                disabled={disabled}
                onClick={startVerification}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
              <Button
                fullWidth
                className={classes.cancel}
                onClick={close}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

import { io, Socket } from 'socket.io-client';
import { API_URL } from '@root/services/api/apiUrls';
import { getTokens } from '@root/services/api/tokenHandler';

export const createSocket = (): Socket => {
  return io(API_URL, {
    reconnection: true,
    reconnectionAttempts: 6,
    transports: ['websocket'],
    query: {
      authorization: getTokens().accessToken || '',
    },
  });
};

export const API_URL: string =
  import.meta.env.REACT_APP_API_URL || 'https://api-stg.fastshoppingstore.com';

const urlsApi = {
  emailVerify: '/user/email-verify',
  emailVerifyExceptLogged: '/user/email-verify-except-logged',
  payOut: '/user/payout-preference',
  payOutIframe: '/kyck/widget-url',
  paypalIframe: '/v1/user/paypal-widget-url',
  pushToCardData: '/kyck/push-to-card',
  routingNumberValidate: 'user/payout-preference/validate-routing-number/',
  dLocalBank: '/user/dlocal-payout-preference/banks/',
  dLocal: '/user/dlocal-payout-preference',
  phoneVerification: '/auth/phone-verification',
  authSso: '/auth/sso',
  refreshToken: '/auth/refresh-tokens',
  details: '/user/details',
  forgotUsername: '/user/forgot-username',
  taxInvoice: '/tax-invoice',
  downloadTaxInvoice: '/tax-invoice/{id}/download',
  getTokenFinal: '/auth/signin-final',
  getToken: '/auth/signin',
  prepaidCard: '/prepaid-card',
  redirectionToken: '/auth/get-redirection-token',
  prepaidCardBalance: '/prepaid-card/my/balance',
  prepaidCards: '/prepaid-card/my/cards',
  updateUsingTipalti: '/v1/user/payout-preference/update-using-tipalti',
};

export default urlsApi;

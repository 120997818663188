import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { AxiosResponse } from 'axios';

export interface IUserDetails {
  firstName: string;
  lastName: string;
  phone: string;
  country:string;
  rankId: number;
}

const request = () =>
  apiClient
    .get<AxiosResponse<IUserDetails>>(ApiUrls.details)
    .then((response) => response.data.data);

export default request;

import deepPurple from '@material-ui/core/colors/deepPurple';
const theme = {
  backGround: '#ccc',

  colors: {
    primaryText: '#fff',
  },
  palette: {
    primary: {
      contrastText: '#fff',
      main: deepPurple[500],
      green: '#08be00',
    },
    background: {
      default: '#fff',
    },
  },
  transparentBackground: {
    dark: 'rgba(0, 0, 0, 0.15)',
    light: 'rgba(255, 255, 255, 0.15)',
  },
};

export default theme;

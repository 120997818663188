import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { IError } from '../requests/authSso';

export interface IUserData {
  countryCode?: string;
  createdAt?: string;
  email?: string;
  firstName?: string;
  id?: string;
  updatedAt?: string;
  canEdit?: boolean;
  currency?: string;
  paypalEmail?: string;
  payoneerEmail?: string;
  swiftCode?: string;
  bankAddress?: string;
  accountHolderName?: string;
  countryOfBirth?: string;
  dateOfBirth?: string;
  bankName?: string;
  accountType?: string;
  exigoUserId?: string;
  routingNumber?: string;
  accountNumber?: string;
  kyckUserId?: string;
  mailingAddress1?: string;
  mailingAddress2?: string;
  mailingCity?: string;
  state?: string;
  postalCode?: string;
  address1?: string;
  address2?: string;
  city?: string;
  lastName?: string;
  method?: string;
  taxId?: string;
  taxIdType?: 'EIN' | 'SSN';
  bankCountry?: string;
  bankCity?: string;
  IBAN?: string;
  dLocal?: any;
  sortCode?: string | number;
  mailingState?: string;
  mailingPostalCode?: string;
  beneficiaryFirstName?: string;
  beneficiaryLastName?: string;
  twilioPhone?: string;
  twilioCode?: string;
  exigoFirstName?: string;
  exigoLastName?: string;
  exigoPhone?: string;
  finInstitutionNumber?: string;
  phoneCode?: string,
  prepaidCardType?: string;
  prepaidPhysicalCardType?: string;
  prepaidCardStatus?: string;
  mobile?: string;
  phone?: string;
  prepaidCardPrice?: number;

  [key: string]: any;
}

export interface IPayOutResponse {
  data: IUserData;
  error?: { data: IError };
}

const request = () => apiClient.get<IPayOutResponse>(ApiUrls.payOut).then((response) => response);
export default request;

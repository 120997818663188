import Header from '../Header';
import { FC } from 'react';
import React from 'react';
import { useStyles } from '../../pages/Payout/styles';

export const Layout: FC<{ wrapperClassName?: string }> = ({ children, wrapperClassName = '' }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <main>{children}</main>
    </div>
  );
};

export default Layout;

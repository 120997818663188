import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    maxWidth: '1200px',
    display: 'flex',
    margin: '60px auto',
    transform: 'translateY(50%)',
    [theme.breakpoints.down('sm')]: {
      transform: 'none',
      flexDirection: 'column-reverse',
      height: 'auto',
    },
  },
  textWrapper: {
    height: '100%',
    flex: '1 1 40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imgWrapper: {
    display: 'flex',
    placeContent: 'center',
    flex: '1 1 40%',
  },
  img: {
    width: '50%',
    marginBottom: '20px',
  },
}));

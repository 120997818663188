import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { INSTANCE_TYPES } from '../../../enums/instance-types';

export interface ISSOResponse {
  message?: string;
  redirectionToken: string;
  redirectionUrl: string;
  wrongDomain: boolean;
}

export interface IError {
  error: string;
  message: string;
  statusCode: number;
}

const request = (token: string) =>
  apiClient
    .get<ISSOResponse>(
      ApiUrls.authSso + `/${encodeURIComponent(token)}?instanceType=${INSTANCE_TYPES.BE_PAY}`
    )
    .then((response) => response);

export default request;

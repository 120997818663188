import React, { FC, useEffect, useRef, useState } from 'react';
import getIframeWidget from '@root/services/api/requests/getIframeLink';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from '@root/pages/Payout/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import notify from '@root/utils/notifications';
import { useDispatch } from 'react-redux';
import { setHomeStep } from '@root/store/system/actions';

type Props = {
  method: string;
};

const TipaltiIframe: FC<Props> = ({ method }) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const [height, setHeight] = useState<number>(750);
  const ref: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const url = await getIframeWidget('paypal');

      if (url) {
        setUrl(url);
      } else {
        setHeight(0);
        notify('error', {
          message: `${method}} method unavailable. Please try another one`,
        });
        setTimeout(() => {
          dispatch(setHomeStep(0));
        }, 2000);
      }
    })();
  }, [setUrl]);

  if (!url) {
    return (
      <div className={classes.fixedLoader}>
        <CircularProgress disableShrink />
      </div>
    );
  }

  return (
    <div ref={ref} id="iframeContainer">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            <span style={{ textTransform: 'capitalize' }}>{method}</span> Info
          </Typography>
          <div>Please complete the form below to use the selected payment method</div>
        </Grid>
      </Grid>
      <iframe
        title="tipaltyIframe_title"
        style={{ border: 'none', padding: '20px', maxWidth: 2000 }}
        id="tipaltyIframe"
        src={url}
        width="100%"
        height={height}
        onLoad={(e) => console.log('e', e)}
      />
    </div>
  );
};

export default TipaltiIframe;

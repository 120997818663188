import { RootState } from '../index';
import { createSelector } from '@reduxjs/toolkit';

const selectSystem = (state: RootState) => state.system;
const selectHomePageData = createSelector(selectSystem, (state) => state.homePageData);

export const selectUserData = createSelector(selectSystem, (state) => state.userData);
export const selectLoading = createSelector(selectSystem, (state) => state.loading);
export const selectHomeStep = createSelector(selectHomePageData, (state) => state.step);
export const selectHomeStepList = createSelector(selectHomePageData, (state) => state.steps);
export const selectHomeEdit = createSelector(selectHomePageData, (state) => state.canEdit);
export const selectHomeLoader = createSelector(selectHomePageData, (state) => state.loader);
export const selectHomeFixedLoader = createSelector(selectHomePageData, (state) => state.fixedLoader);
export const selectHomeConfirmationModal = createSelector(selectHomePageData, (state) => state.confirmationModal);
export const selectPrepaidCardModal = createSelector(selectHomePageData, (state) => state.prepaidCardModal);
export const selectHomeSuccessMessage = createSelector(selectHomePageData, (state) => state.successMessage);
export const selectHomeUserData = createSelector(selectHomePageData, (state) => state.userData);
export const selectHomeInitialData = createSelector(selectHomePageData, (state) => state.initialData);
export const selectPushToCardData = createSelector(selectSystem, (state) => state.pushToCardData);
export const selectIsWaitingForWebhook = createSelector(selectSystem, (state) => state.waitingForWebhook);

import apiClient from '../client';
import ApiUrls, { API_URL } from '../apiUrls';
import axios from 'axios';

export interface IInitiateCard {
  countryCode: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  type: string;
  option: string;
  status: string;
}

export interface IPrepaidCardBalance {
  available_amount: number;
  balance: number;
  blocked_amount: number;
}

export interface IPrepaidCard {
  id: number;
  colsUserId: null;
  status: PREPAID_CARD_STATUS;
  firstName: string;
  lastName: string;
  countryCode: string;
  mobile: string;
  email: string;
  cardOption: string;
  cardType: string;
  amountPaid: null;
  createdAt: string;
  updatedAt: string;
  setupFee: number;
}

export interface IPrepaidCardPlan {
  pricing: {
    price: number;
  };
}

export enum PREPAID_CARD_STATUS {
  INITIATED = 'INITIATED',
  PROCESSING = 'PROCESSING',
  ACTIVE = 'ACTIVE',
}

const getPrepaidCard = (): Promise<IPrepaidCard | null> =>
  apiClient
    .get(ApiUrls.prepaidCard)
    .then((response) => response.data.data)
    .catch(() => null);

const initiatePrepaidCard = (data: IInitiateCard): Promise<IPrepaidCard | null> => {
  const { status, ...rest } = data;

  const option = data.option || 'physicalAndVirtual';

  if (status) {
    return apiClient
      .put(`${ApiUrls.prepaidCard}/initiate/edit`, { ...rest, option })
      .then((response) => response.data.data)
      .catch(() => null);
  }
  return apiClient
    .post(`${ApiUrls.prepaidCard}/initiate?type=${data.type}&option=${option}`, { ...rest, option })
    .then((response) => response.data.data)
    .catch(() => null);
};

const editPrepaidCard = (data: IInitiateCard): Promise<IPrepaidCard | null> =>
  apiClient
    .put(ApiUrls.prepaidCard, data)
    .then((response) => response.data.data)
    .catch(() => null);

const getPrepaidCardPlan = (planId: string, country: string): Promise<IPrepaidCardPlan | null> =>
  axios
    .get(`${API_URL}/plan/${planId}?language=en&uiCountry=${country}`)
    .then((response) => response.data.data)
    .catch(() => null);

const getPrepaidCardBalance = (): Promise<IPrepaidCardBalance | null> =>
  apiClient
    .get(ApiUrls.prepaidCardBalance)
    .then((response) => response.data.data)
    .catch(() => null);

const getMyPrepaidCards = (): Promise<IPrepaidCardBalance | null> =>
  apiClient
    .get(ApiUrls.prepaidCards)
    .then((response) => response.data.data)
    .catch(() => null);

export default {
  getPrepaidCard,
  initiatePrepaidCard,
  editPrepaidCard,
  getPrepaidCardPlan,
  getPrepaidCardBalance,
  getMyPrepaidCards,
};

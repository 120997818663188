import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { INSTANCE_TYPES } from '../../../enums/instance-types';

export interface ITokenData {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

export interface ITokenResponse {
  data: { data: ITokenData } | ITokenData;
}

const request = () =>
  apiClient
    .get<ITokenResponse>(
      `${ApiUrls.getTokenFinal}?instanceType=${INSTANCE_TYPES.BE_PAY}&devMode=${
        process.env.NODE_ENV === `development`
      }`
    )
    .then((response) => response);

export default request;

interface ILinks {
  home: string;
  login: string;
  forgotPassword: string;
  forgotUsername: string;
  forgotPasswordSuccess: string;
  createNewPassword: string;
  createNewPasswordSuccess: string;
  createEditAdmin: string;
  customers: string;
  orders: string;
  admins: string;
  coupons: string;
  createEditCoupon: string;
  createEditPermissionRole: string;
  permissionRoles: string;
  invoices: string;
}

const links: ILinks = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  forgotUsername: '/forgot-username',
  forgotPasswordSuccess: '/forgot-password-success',
  createNewPassword: '/create-new-password',
  createNewPasswordSuccess: '/create-new-password-success',
  createEditAdmin: '/user-create-edit',
  customers: '/customers',
  admins: '/admins',
  orders: '/orders',
  coupons: '/coupons',
  invoices: '/invoices',
  createEditCoupon: '/create-edit-coupon',
  createEditPermissionRole: '/create-edit-permission-role',
  permissionRoles: '/admin-permission-roles',
};

export default links;

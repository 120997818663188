import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { IUserData } from './getPayOutInfo';

export interface IAddPayoutResponse {
  data: Array<{ code: number; message: string }> | IUserData;
  message?: string;
  statusCode?: number;
  error?: {
    data: {
      message: string;
    };
  };
}

const request = (data: any) =>
  apiClient.post<IAddPayoutResponse>(ApiUrls.payOut, data).then((response) => response);

export default request;

import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '@media(max-width: 780px)': {
        display: 'none',
      },
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    menuList: {
      position: 'relative',
      zIndex: 99999999,
      width: 175,
      backgroundColor: '#fff',
    },
    zIndex: { zIndex: 99999999 },
    button: {
      background: '#673ab7',
      boxShadow: 'none',
      border: 'none',
      borderRadius: 0,
      textTransform: 'capitalize',
      fontSize: 14,
    },
    icon: {
      width: 12,
    },
  })
);

export default function MenuListComposition() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleMenuItemClick = () => {
    dispatch(push('/invoices'));
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div>
          <Button
            ref={anchorRef}
            variant="contained"
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
            className={classes.button}
            endIcon={<ArrowForwardIosIcon className={classes.icon} fontSize={'small'} />}
          >
            Resources
          </Button>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            className={classes.zIndex}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      className={classes.menuList}
                    >
                      <MenuItem onClick={handleMenuItemClick}>Tax Invoices</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Paper>
    </div>
  );
}

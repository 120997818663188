import apiClient from '../client';
import ApiUrls from '../apiUrls';

export type IForgotData = {
  email: string;
  initiator: string;
  url: string;
};

export interface IForgotRequest {
  data: IForgotData;
}

const request = (data: IForgotData) => apiClient.post<IForgotRequest>(ApiUrls.forgotUsername, data).then((response) => response);
export default request;

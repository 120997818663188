import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { IVDLocalCountryInfo } from '../../../components/DLocalForm/types';

export interface IVerifyResponse {
  data: IVDLocalCountryInfo;
}

const request = (countryCode: string) =>
  apiClient.get<IVerifyResponse>(ApiUrls.dLocalBank + countryCode).then((response) => response);

export default request;

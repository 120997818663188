import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { setHomeStep, setHomeUserData, setPrepaidCardModal } from '../../store/system/actions';
import { selectHomeUserData, selectPrepaidCardModal } from '../../store/system/selectors';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import InputSelect from '@root/components/InputSelect';
import { FormControl } from '@material-ui/core';

const getMessage: (p: number, is: boolean, setupFee: number) => string = (
  price,
  isPrepaidCardAlreadyBought,
  setupFee
) => {
  if (isPrepaidCardAlreadyBought) {
    return `There is a processing fee of $${price} for each card. All fees are non-refundable. Once you have paid for your card(s), you will be able to select your unique design`;
  }
  return `To complete the account there is a set up fee of $${setupFee}. There is a processing fee of $${price} for each card. All fees are non-refundable. Once you have paid for your card(s), you will be able to select your unique design.`;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    paperModal: {
      padding: theme.spacing(2),
      width: '100%',
      maxWidth: 600,
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      backgroundColor: '#fff',
      margin: '0 20px',
    },

    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },

    wrapper: {
      alignItems: 'center',
      position: 'relative',
      maxWidth: 500,
      width: '90%',
      background: '#fff',
      margin: '30px auto',
    },

    title: {
      fontSize: 40,
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#421c60',
      marginBottom: 30,
    },

    textInfo: {
      fontSize: 20,
      fontWeight: 500,
      color: '#363636',
      marginBottom: 15,
      textAlign: 'center',
    },

    long: {
      margin: '0 auto 20px',
      fontSize: 16,
    },

    form: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 0 55px 0',
    },

    input: {
      display: 'block',
      width: 58,
      height: 60,
      borderRadius: 4,
      boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.06)',
      border: 'solid 1px #e3d4ef',
      backgroundColor: '#fff',
      margin: '0 7px',
      textAlign: 'center',
      fontWeight: 500,
      color: '#5d01a2',
      fontSize: 20,
      transition: 'all 0.3s',
      '&:focus': {
        border: 'solid 1px #5d01a2',
      },
    },

    filled: {
      backgroundColor: 'rgba(184, 169, 196, 0.06)',
    },

    cancel: {
      marginTop: 10,
    },

    radioGroupContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: '12px',
      alignItems: 'flex-start',

      '& .MuiFormControlLabel-root': {
        margin: '0px !important',
      },

      '& .MuiButtonBase-root': {
        marginTop: -25,
        marginLeft: -10,
      },
    },

    wrapperConfirmButton: {
      width: '100%',
    },

    tooltipWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    },

    tooltip: {
      color: 'red',
      position: 'absolute',
      top: '-20px',
      fontSize: 12,
    },
  })
);

const maxNumOfPrepaidCards = 3;

export default function PrepaidCardModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(selectPrepaidCardModal);
  const userData = useSelector(selectHomeUserData);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [numOfPrepaidPhysicalCard, setNumOfPrepaidPhysicalCard] = useState<number>(1);
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
  const prepaidPhysicalCard = userData?.prepaidCards?.physical_cards || 0;

  const isPrepaidCardAlreadyBought =
    !!userData?.prepaidCards?.virtual_cards || !!userData?.prepaidCards?.physical_cards;
  const amountLeft = maxNumOfPrepaidCards - prepaidPhysicalCard;

  const handleNumOfPrepaidCard = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setNumOfPrepaidPhysicalCard(Number(e.target.value));
  };

  const setupFee = userData?.prepaidCardData?.setupFee || 0;

  const close = () => {
    dispatch(setPrepaidCardModal(false));
    setActiveStep(1);
  };

  const setUserData = (data: {
    method?: string | undefined | null;
    countryCode?: string;
    prepaidCardQuantity: number;
  }) => {
    dispatch(setHomeUserData(data));
  };

  const onOk = (e: React.FormEvent) => {
    dispatch(setPrepaidCardModal(false));
    dispatch(setHomeStep(1));
  };

  const handleMouseOver = (e: React.MouseEvent) => {
    if (amountLeft === 0) {
      setIsShowTooltip(true);
    }
  }

  const handleMouseOut = (e: React.MouseEvent) => {
    if (isShowTooltip) {
      setIsShowTooltip(false);
    }
  }

  useEffect(() => {
    setUserData({
      ...userData,
      // @ts-ignore
      prepaidCardType: 'physicalAndVirtual',
    });
  }, []);

  useEffect(() => {
    setUserData({
      ...userData,
      prepaidCardQuantity: numOfPrepaidPhysicalCard,
    });
  }, [numOfPrepaidPhysicalCard]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paperModal}>
          <div className="container">
            <>
              <div className={classes.wrapper}>
                <div className={classes.textInfo}>
                  Please confirm to continue with your Pre-Paid card
                </div>
                <div className={clsx(classes.textInfo, classes.long)}>
                  {getMessage(
                    userData.prepaidCardPrice || 149,
                    isPrepaidCardAlreadyBought,
                    setupFee
                  )}
                </div>
                <Grid item xs={12}>
                  <form onSubmit={undefined}>
                    <Grid container spacing={2} direction={'column'}>
                      <Grid item xs={12} md={12}>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          value={userData.prepaidCardType}
                          style={{ margin: '0 auto', display: 'block', textAlign: 'center' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setUserData({
                              ...userData,
                              // @ts-ignore
                              prepaidCardType: e.target.value,
                              prepaidPhysicalCardType:
                                e.target.value === 'virtual'
                                  ? ''
                                  : userData.prepaidPhysicalCardType,
                            });
                          }}
                        >
                          {/* <FormControlLabel
                            value='physical'
                            control={<Radio color='primary' />}
                            label='Physical card'
                          />
                          <FormControlLabel
                            value='virtual'
                            control={<Radio color='primary' />}
                            label='Virtual Card'
                          /> */}
                          {activeStep === 1 && (
                            <FormControlLabel
                              value="getAnotherPrepaidCard"
                              control={<Radio color="primary" />}
                              label={
                                prepaidPhysicalCard > 0
                                  ? 'Get another Pre-paid Card'
                                  : 'Get Pre-paid card'
                              }
                            />
                          )}
                        </RadioGroup>
                        {activeStep === 2 && (
                          <FormControl fullWidth>
                            <InputSelect
                              id="prepaidCards"
                              name="prepaidCards"
                              options={[1, 2, 3]
                                .filter((num) => num <= amountLeft)
                                .map((value) => ({ value, label: value }))}
                              disabled={amountLeft === 0}
                              value={numOfPrepaidPhysicalCard}
                              onChange={handleNumOfPrepaidCard}
                            />
                          </FormControl>
                        )}

                        {/* {userData.prepaidCardType !== 'virtual' && (
                          <Box mt={2}>
                            <div style={{textAlign: 'center', marginTop: -20, marginBottom: 20}}>Choose your design</div>
                            <RadioGroup
                              row
                              aria-label='position'
                              name='position'
                              id='prepaidPhysicalCardType'
                              className={classes.radioGroupContainer}
                              value={userData.prepaidPhysicalCardType}
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  // @ts-ignore
                                  prepaidPhysicalCardType: e.target.value,
                                });
                                
                              }}
                              >

                              <CustomRadioButton value="design-1" label="Design 1" image={design1} />
                              <CustomRadioButton value="design-2" label="Design 2" image={design2} />
                              <CustomRadioButton value="design-3" label="Design 3" image={design3} />
                            </RadioGroup>
                          </Box>
                        )}       */}
                      </Grid>
                    </Grid>

                    <input type="submit" hidden />
                  </form>
                </Grid>
              </div>

              {activeStep === 1 && (
                <Button
                  fullWidth
                  onClick={() => setActiveStep(2)}
                  variant="contained"
                  color="primary"
                  disabled={!userData.prepaidCardType}
                >
                  Next step
                </Button>
              )}

              {activeStep === 2 && (
                <div className={classes.tooltipWrapper}>
                  {isShowTooltip && (
                    <div className={classes.tooltip}>
                      <span>You already have the maximum numbers of cards allowed</span>
                    </div>
                  )}
                  <div
                    className={classes.wrapperConfirmButton}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <Button
                      fullWidth
                      onClick={onOk}
                      disabled={amountLeft === 0}
                      variant="contained"
                      color="primary"
                    >
                      Confirm
                    </Button>
                  </div>
                  <Button
                    fullWidth
                    className={classes.cancel}
                    onClick={close}
                    variant="contained"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formContainer: {
    maxWidth: 400,
  },
  formControlSelect: {
    width: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    '& .inputInfo': {
      fontSize: '12px',
      lineHeight: 1,
      '&:last-child': {
        marginBottom: '-5px',
      },
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paymentTitle: {
    margin: theme.spacing(2, 0, 2),
  },
}));

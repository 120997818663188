import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { push } from 'connected-react-router';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Details from '../Details';
import Summary from '../Summary';
import {
  selectHomeConfirmationModal,
  selectHomeEdit,
  selectHomeFixedLoader,
  selectHomeInitialData,
  selectHomeLoader,
  selectHomeStep,
  selectHomeSuccessMessage,
  selectHomeUserData,
  selectUserData,
} from '../../store/system/selectors';
import PaymentMethod from '../PaymentMethod';
import pageLinks from '../../utils/pageLinks';
import { getSteps, isUserLogged } from '../../utils/helpers';
import DLocalForm from '../../components/DLocalForm';
import CountryMethodStep from '../CountryMethodStep';
import HomeStepper from '../../components/HomeStepper';
import { dLocalCountries } from '../../constants/countries';
import ConfirmPhoneModal from '../../components/ConfirmPhoneModal';
import {
  fetchUserData,
  setHomeEdit,
  setHomeStep,
  setHomeStepList,
  setHomeUserData,
} from '../../store/system/actions';
import { useStyles } from './styles';
import Layout from '../../components/Layout';
import { PREPAID_CARD_STATUS } from '@root/services/api/requests/prepaidCard';

export default function HomePage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userAuthData = useSelector(selectUserData);
  const userData = useSelector(selectHomeUserData);
  const activeStep = useSelector(selectHomeStep);
  const canEdit = useSelector(selectHomeEdit);
  const loader = useSelector(selectHomeLoader);
  const fixedLoader = useSelector(selectHomeFixedLoader);
  const confirmationModal = useSelector(selectHomeConfirmationModal);
  const showSuccessMessage = useSelector(selectHomeSuccessMessage);
  const initialData = useSelector(selectHomeInitialData);
  const beBackOfficeToken = new URLSearchParams(new URL(window.location.href).search).get('Token');
  const ssoToken = new URLSearchParams(new URL(window.location.href).search).get('tempToken');
  const { countryCode, method, prepaidCardData, createdAt } = userData;

  const steps = getSteps(method || prepaidCardData?.method, countryCode, createdAt);
  const [showPrepaidCardMessage, setShowPrepaidCardMessage] = React.useState(false);

  const lastStep = steps.length - 1;
  const showWithdrawalInfo = (beBackOfficeToken || ssoToken) && activeStep !== lastStep;
  const showEditButton = !!initialData.method;
  const isKyckMethod = ['cash', 'pushToCard', 'prepaidCard', 'PayPal'].includes(method as string);
  const isDLocalFlow = method === 'bankWire' && dLocalCountries.includes(countryCode as string);
  const showLoader =
    (userData.countryCode === undefined && (beBackOfficeToken || ssoToken)) || loader;
  // const showDevelopMessage = !beBackOfficeToken;

  ['Payment preferences', 'Payee details', 'Payment method', 'BE Pay Details'];

  useEffect(() => {
    if (userAuthData?.accessToken) {
      dispatch(fetchUserData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthData?.accessToken]);

  useEffect(() => {
    if (!canEdit && initialData.method) {
      dispatch(setHomeUserData(initialData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canEdit]);

  useEffect(() => {
    dispatch(setHomeStepList(steps));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps.length]);

  useEffect(() => {
    // TODO: move to sagas, after sagas redirections get fixed
    if (!isUserLogged() && !beBackOfficeToken && !ssoToken) {
      dispatch(push(pageLinks.login));
    }
  }, [beBackOfficeToken, ssoToken, dispatch]);

  useEffect(() => {
    if (
      userData.prepaidCardData?.method === 'prepaidCard' &&
      !!localStorage.getItem('showPrepaidCardMessage') &&
      userData.prepaidCardStatus === PREPAID_CARD_STATUS.ACTIVE
    ) {
      setShowPrepaidCardMessage(true);
      localStorage.removeItem('showPrepaidCardMessage');
    }
  }, [method, userData.prepaidCardStatus]);

  const handleNext = () => {
    if (activeStep <= steps.length + 1) {
      dispatch(setHomeStep(activeStep + 1));
    }
  };

  const handleBack = () => {
    dispatch(setHomeStep(activeStep - 1));
  };

  const cancelClick = () => {
    dispatch(setHomeEdit(false));
  };

  if (showLoader) {
    return (
      <div className={classes.fixedLoader}>
        <CircularProgress disableShrink />
      </div>
    );
  }

  return (
    <Layout>
      <HomeStepper steps={steps} />

      {fixedLoader && (
        <div className={classes.fixedLoader}>
          <CircularProgress disableShrink />
        </div>
      )}

      {showWithdrawalInfo && (
        <Box width={'100%'} display={'flex'} justifyContent={'center'} padding={'0 20px'}>
          <Typography component="span" className={`${classes.instructions} ${classes.note}`}>
            NOTE: Withdrawal under $3000, fee is flat rate of 30$. Withdrawal above $3000, flat rate
            fee is 2%
          </Typography>
        </Box>
      )}

      {showSuccessMessage && (
        <Box width={'100%'} display={'flex'} justifyContent={'center'} padding={'0 20px'}>
          <Typography component="span" className={`${classes.instructions} ${classes.note}`}>
            Thank you, your payout info has been successfully {initialData.id ? 'updated' : 'saved'}
            ! You can edit your payout details by clicking on "EDIT" below
          </Typography>
        </Box>
      )}

      {showPrepaidCardMessage && (
        <Box width={'100%'} display={'flex'} justifyContent={'center'} padding={'0 20px'}>
          <Typography component="span" className={`${classes.instructions} ${classes.note}`}>
            Thank you for completing the prepaid card request. We have received your e-wallet
            payment. We will send you an email with further instructions to the provided email
            address.
          </Typography>
        </Box>
      )}

      <div>
        <div>
          <Typography component="span" className={classes.instructions}>
            <Grid item xs={12} className={classes.mainGrid}>
              <Paper className={classes.paper}>
                {activeStep === steps.length && (
                  <div>
                    <Typography component="span" className={classes.instructions}>
                      Your payout preference was submitted successfully. Thank you!
                    </Typography>
                  </div>
                )}
                {activeStep === 0 && <CountryMethodStep />}
                {activeStep === 1 && <Details />}
                {activeStep === 2 && steps[2] === 'Payment method' && !isKyckMethod && (
                  <>{isDLocalFlow ? <DLocalForm /> : <PaymentMethod />}</>
                )}
                {activeStep === lastStep && <Summary />}
                {!canEdit && activeStep < lastStep && (
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      disabled={!activeStep}
                      onClick={() => handleBack()}
                      className={classes.backButton}
                    >
                      Back
                    </Button>

                    <div>
                      {showEditButton && (
                        <Button
                          onClick={() => {
                            canEdit ? cancelClick() : dispatch(setHomeEdit(!canEdit));
                          }}
                          variant="outlined"
                          className={classes.editButton}
                        >
                          {canEdit ? 'Cancel' : 'Edit'}
                        </Button>
                      )}
                      <Button
                        onClick={handleNext}
                        disabled={!method}
                        variant="contained"
                        color="primary"
                      >
                        {activeStep === steps.length - 2 ? 'Finish' : 'Next'}
                      </Button>
                    </div>
                  </div>
                )}
              </Paper>
            </Grid>
          </Typography>
        </div>
      </div>
      {confirmationModal && <ConfirmPhoneModal />}
    </Layout>
  );
}

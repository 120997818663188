import React, { useEffect, useRef, useState } from 'react';
import getIframeWidget from '@root/services/api/requests/getIframeLink';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from '@root/pages/Payout/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import notify from '@root/utils/notifications';
import { useDispatch } from 'react-redux';
import { setHomeStep } from '@root/store/system/actions';

export default function IframeCmp() {
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const [height, setHeight] = useState<number>(524);
  const ref: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
  const classes = useStyles();
  
  useEffect(() => {
    (async () => {
      const url = await getIframeWidget('pushToCard');
      if (url) {
        setUrl(url);
      } else {
        setHeight(0);
        notify('error', {
          message: 'Push to card method unavailable. Please try another one',
        });
        setTimeout(() => {
          dispatch(setHomeStep(0));
        }, 2000);
      }
    })();
  }, [setUrl]);
  
  if (!url) {
    return (
      <div className={classes.fixedLoader}>
        <CircularProgress disableShrink />
      </div>
    );
  }
  
  return (
    <div ref={ref} id='iframeContainer'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' component='h6'>
            Card Info
          </Typography>
          <div>Please complete the form below to use the selected payment method</div>
        </Grid>
      </Grid>
      <iframe
        title='kyck'
        style={{ border: 'none', padding: '20px', maxWidth: 600 }}
        id='pushToCardIframe'
        src={url}
        width='100%'
        height={height}
      />
    </div>
  );
}

import React from 'react';
import validateEmail, { VerifyResponse } from '../services/api/requests/validateExceptLogged';

interface EmailData {
  value: string;
  name: string;
}

const useEmailValidator = () => {
  const [emailData, checkEmailData] = React.useState<EmailData | null>(null);
  const [emailErrors, setErrors] = React.useState<{ [key: string]: string } | null>(null);
  
  React.useEffect(() => {
    const validate = async () => {
      const { value, name } = emailData as EmailData;
      
      try {
        // @ts-ignore
        const { data }: VerifyResponse = await validateEmail(`?email=${encodeURIComponent(value)}`);
        
        if (!data?.isSameAddress && data?.isValid && !data.inUse) {
          return setErrors(null);
        }
        
        
        if (!data?.isSameAddress && data?.isValid && data.inUse && ['paypalEmail', 'payoneerEmail'].includes(name)) {
          return setErrors(null);
        }
        
        
        if (!data.isSameAddress && data.inUse) {
          return setErrors({ ...emailErrors, [name]: 'Email address already taken' });
        }
        
        if (data.statusCode === 400) {
          setErrors({ ...emailErrors, [name]: data.message as string });
        }
      } catch (e) {
        setErrors({ ...emailErrors, [name]: e?.response?.data?.message || e });
      }
    };
    
    emailData && validate();
    
    return () => {
      setErrors(null);
      checkEmailData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(emailData)]);
  
  return {
    emailErrors,
    validateEmail: ({ name, value }: EmailData) => checkEmailData({ name, value }),
  };
};

export default useEmailValidator;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import states from '../../utils/states';

interface CountryType {
  code: string;
  name: string;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface IProps {
  value?: string;
  id: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange(v: string): void;
}

const StateSelect: React.FC<IProps> = ({
  value,
  id,
  onChange,
  error,
  helperText,
  disabled = false,
}) => {
  const classes = useStyles();

  const onSelectChange = (e: any, selectedOption: any = []) => {
    onChange(selectedOption?.code || '');
  };

  const option = states.find((item: { code: string; name: string }) => item.code === value);

  return (
    <Autocomplete
      id={id}
      options={states as CountryType[] | []}
      value={option || null}
      onChange={onSelectChange}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      disabled={disabled}
      fullWidth
      getOptionLabel={(option) => option.name}
      renderOption={(option) => <React.Fragment>{option.name}</React.Fragment>}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a State"
          variant="outlined"
          fullWidth
          required
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default StateSelect;

import * as React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Link } from '@material-ui/core';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import forgotUsername from '../../services/api/requests/forgotUsername';
import { useStyles } from './styles';
import pageLinks from '../../utils/pageLinks';
import setNotification from '../../utils/notifications';
import KeyboardBackspaceOutlined from '@material-ui/icons/KeyboardBackspaceOutlined';
import { inputCreator } from '../../components/BaseForm';

interface IValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
});

const initialValues: IValues = {
  email: '',
};

const ForgotUsername: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const successMessage = 'Email successfully sent';

  const onFormSubmit = async (values: IValues) => {
    const { email } = values;

    if (!email) {
      return;
    }

    const url = window.location.origin;
    const initiator = 'SHOP';

    try {
      const response = await forgotUsername({ email, initiator, url });
      if (response) {
        setNotification('success', { message: successMessage as string });
        setTimeout(() => dispatch(push(pageLinks.login)), 3000);
      }
    } catch (e) {
      setNotification('error', { message: e as string });
    }
  };

  const fk: FormikProps<IValues> = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: onFormSubmit,
  });

  const createInput = inputCreator<IValues>(fk);

  return (
    <div className={classes.root}>
      <Link href={pageLinks.login} variant='body1' style={{ textDecoration: 'none' }} className={classes.back}>
        <KeyboardBackspaceOutlined />
        Back
      </Link>
      <div className={classes.paper}>
        <Grid item xs={8} sm={12} md={8} component={Paper} elevation={0} square>
          <Typography component='h3' variant='h6' className={classes.title}>
            Enter the email address associated with your account and we'll send you a reminder.
          </Typography>
          <div>
            {createInput({
              id: 'email',
              label: 'Email',
              md: 12,
            })}

            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => fk.handleSubmit()}
              className={classes.submit}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default ForgotUsername;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useStyles } from '@root/pages/TaxInvoices/styles';
import React from 'react';
import download from '../../assests/images/download.svg';
import eye from '../../assests/images/eye.svg';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import TableSkeleton from '../../components/TableSkeleton';
import { ITaxInvoiceItem } from '../../services/api/requests/get-tax-invoices';
import { IMeta } from '../../services/types';

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Invoice ID', disableSort: true },
  { id: 'admin', numeric: false, disablePadding: false, label: 'Invoice Date', disableSort: true },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: '', disableSort: true },
];

interface IProps {
  list: ITaxInvoiceItem[];
  handleChangePage(event: unknown, newPage: number): void;
  handleChangeRowsPerPage(event: unknown): void;
  downloadInvoice(invoiceId: number): void;
  openInvoice(invoiceId: number): void;
  loading: boolean;
  meta: IMeta;
}

const Invoices = ({
  loading,
  list,
  handleChangePage,
  handleChangeRowsPerPage,
  meta,
  openInvoice,
  downloadInvoice,
}: IProps) => {
  const classes = { ...useStandardTableStyles(), ...useStyles() };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <CustomTableHead classes={classes} headCells={headCells} />

          <TableBody>
            {loading && <TableSkeleton />}
            {!loading &&
              list?.map((item: ITaxInvoiceItem) => {
                const { invoiceId, invoiceDate } = item;
                return (
                  <TableRow key={invoiceId}>
                    <TableCell align="left">{invoiceId}</TableCell>
                    <TableCell align="left">{invoiceDate}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          openInvoice(invoiceId);
                        }}
                        color="inherit"
                        className={classes.actionButton}
                        endIcon={<img src={eye} alt="" />}
                      >
                        View
                      </Button>

                      <Button
                        onClick={() => {
                          downloadInvoice(invoiceId);
                        }}
                        color="inherit"
                        className={classes.actionButton}
                        endIcon={<img src={download} alt="" />}
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}

            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no invoices</TableCell>
                {!list.length && <TableCell />}
                {!list.length && <TableCell />}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={meta.limit}
        page={meta.page - 1}
        onChangePage={(e: unknown, newPage: number) => handleChangePage(e, newPage)}
        onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeRowsPerPage(event)
        }
      />
    </>
  );
};

export default Invoices;

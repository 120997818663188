import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '@root/store/system/actions';
import { selectHomeUserData } from '@root/store/system/selectors';
import { isUserLogged } from '@root/utils/helpers';

import Box from '@material-ui/core/Box';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import MenuListComposition from '@root/components/HeaderMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    toolbar: {
      width: '100%',
      padding: '0 30px',
      '@media(min-width: 780px)': {
        padding: '0 60px',
      },
    },

    mobileBalance: {
      display: 'grid',
      padding: 10,
      '@media(min-width: 780px)': {
        display: 'none',
      },
    },

    desktopBalance: {
      fontSize: '15px',
      fontWeight: 'bold',
      display: 'inline',
      '@media(max-width: 780px)': {
        display: 'none',
      },
    },

    desktopBalanceWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 30,
    },

    link: ({ homeLinkActive }: { homeLinkActive: boolean }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '46px',
      marginRight: theme.spacing(8),
      color: '#fff',
      fontSize: 14,
      lineHeight: '16px',
      textDecoration: 'none',
      borderBottom: '1px solid #fff',
      paddingBottom: '2px',
      transition: 'all 0.3s ease-in-out',
      fontWeight: homeLinkActive ? 'bold' : 'normal',
      '&:hover': {
        fontWeight: 'bold',
      },
    }),
    endIcon: {
      margin: 0,
    },
  })
);

export default function MenuAppBar() {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const onLogout = () => dispatch(logOutUser());
  const homeLinkActive = window.location.pathname === '/';
  const classes = useStyles({ homeLinkActive });

  const userData = useSelector(selectHomeUserData);

  if (!isUserLogged()) {
    return null;
  }

  const nextBalance =
    userData.prepaidCardBalanceData &&
    userData.prepaidCardBalanceData.total_requested -
      (userData.prepaidCardBalanceData.total_transactions +
        userData.prepaidCardBalanceData.balance);

  const instantBalance = userData.prepaidCardBalanceData && userData.prepaidCardBalanceData.balance;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" alignItems="center" className={classes.title}>
            <Typography variant="h6">
              <Link to="/" className={classes.link}>
                Payout
              </Link>
            </Typography>

            <MenuListComposition />
          </Box>

          <Box display="flex" flexDirection="row">
            {(!!instantBalance || !!nextBalance) && (
              <Box className={classes.desktopBalanceWrapper}>
                <Box className={classes.desktopBalance}>
                  <span>Instant Balance: </span>
                  <span>€{instantBalance?.toFixed(2)}</span>
                </Box>{' '}
                <Box className={classes.desktopBalance}>
                  <span>Next Balance: </span>
                  <span>€{nextBalance?.toFixed(2)}</span>
                </Box>
              </Box>
            )}

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {(!!instantBalance || !!nextBalance) && (
                <MenuItem className={classes.mobileBalance}>
                  <Box>
                    <Box>
                      <span>Instant Balance: </span>
                      <span>€{instantBalance?.toFixed(2)}</span>
                    </Box>
                    <Box>
                      <span>Next Balance: </span>
                      <span>€{nextBalance?.toFixed(2)}</span>
                    </Box>
                  </Box>
                </MenuItem>
              )}
              <MenuItem onClick={() => dispatch(push('/invoices'))}>Tax Invoices</MenuItem>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

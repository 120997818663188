import { setHomeEdit, setHomeStep } from '../../store/system/actions';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useStyles } from '../../pages/Payout/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHomeEdit,
  selectHomeInitialData,
  selectHomeStep,
  selectHomeStepList,
  selectHomeUserData,
} from '../../store/system/selectors';

export default function CustomButtons(data: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const canEdit = useSelector(selectHomeEdit);
  const activeStep = useSelector(selectHomeStep);
  const steps = useSelector(selectHomeStepList);
  const userData = useSelector(selectHomeUserData);
  const initialData = useSelector(selectHomeInitialData);
  const showEditButton = !!initialData.method;
  const { countryCode, method } = userData;
  
  const resetForms = () => {
    dispatch(setHomeEdit(false));
  };
  
  const handleBack = () => {
    dispatch(setHomeStep(activeStep - 1));
  };
  
  const cancelClick = () => {
    data?.resetForm?.();
    resetForms();
  };
  
  const editClick = () => {
    data && data.onEdit && data.onEdit();
    dispatch(setHomeEdit(!canEdit));
  };
  
  let submitButtonText = activeStep === (steps?.length || 0) - 2 ? 'Submit' : 'Next';
  
  
  if (data?.submitText) {
    submitButtonText = data.submitText;
  }
  
  
  return (
    <div className={classes.buttonContainer}>
      <Button
        variant='outlined'
        disabled={!activeStep}
        onClick={handleBack}
        className={classes.backButton}
      >
        Back
      </Button>
      
      <div>
        {showEditButton && (
          <Button
            onClick={() => (canEdit ? cancelClick() : editClick())}
            variant='outlined'
            className={classes.editButton}
          >
            {canEdit ? 'Cancel' : 'Edit'}
          </Button>
        )}
        <Button
          type='submit'
          disabled={!method || !countryCode || (data && data.isDisabled)}
          variant='contained'
          color='primary'
        >
          {submitButtonText}
        </Button>
      </div>
    </div>
  );
}

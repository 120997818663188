import { ITokenData } from '../../services/api/requests/getTokenFinal';
import { IUserData } from '../../services/api/requests/getToken';
import { IPhoneParams } from '../../services/api/requests/verifyPhone';
import { IUserData as IUserDataHome } from '../../services/api/requests/getPayOutInfo';
import { IPushToCardData } from '@root/services/api/requests/getPushToCardData';

export const CHANGE_THEME = 'CHANGE_THEME';
export const SET_LOADER = 'SET_LOADER';
export const START_DEFAULT_LOGIN = 'START_DEFAULT_LOGIN';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_HOME_STEP = 'SET_HOME_STEP';
export const SET_HOME_STEP_LIST = 'SET_HOME_STEP_LIST';
export const SET_HOME_EDIT = 'SET_HOME_EDIT';
export const SET_HOME_USER_DATA = 'SET_HOME_USER_DATA';
export const SET_HOME_INITIAL_DATA = 'SET_HOME_INITIAL_DATA';
export const SET_HOME_LOADER = 'SET_HOME_LOADER';
export const SET_HOME_FIXED_LOADER = 'SET_HOME_FIXED_LOADER';
export const SET_HOME_CONFIRMATION_MODAL = 'SET_HOME_CONFIRMATION_MODAL';
export const SET_PREPAID_CARD_MODAL = 'SET_PREPAID_CARD_MODAL';
export const SET_HOME_SUCCESS_MESSAGE = 'SET_HOME_SUCCESS_MESSAGE';
export const HOME_PAGE_RESET = 'HOME_PAGE_RESET';
export const SEND_TWILIO_CODE = 'SEND_TWILIO_CODE';
export const VERIFY_TWILIO_CODE = 'VERIFY_TWILIO_CODE';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const SAVE_EDIT_DATA = 'SAVE_EDIT_DATA';
export const FETCH_PREPAID_CARD_PLAN = 'FETCH_PREPAID_CARD_PLAN';
export const FETCH_PUSH_TO_CARD_DATA = 'FETCH_PUSH_TO_CARD_DATA';
export const SET_PUSH_TO_CARD_DATA = 'SET_PUSH_TO_CARD_DATA';
export const SET_WAITING_FOR_WEBHOOK = 'SET_WAITING_FOR_WEBHOOK';

export interface ChangeThemeAction {
  type: typeof CHANGE_THEME;
  payload: string;
}

export interface SetWaitingForWebhook {
  type: typeof SET_WAITING_FOR_WEBHOOK;
  payload: boolean;
}

export interface FetchPrepaidCardPlanAction {
  type: typeof FETCH_PREPAID_CARD_PLAN;
  payload: string;
}

export interface FetchPushToCardDataAction {
  type: typeof FETCH_PUSH_TO_CARD_DATA;
}

export interface SetPushToCardDataAction {
  type: typeof SET_PUSH_TO_CARD_DATA;
  payload: IPushToCardData;
}

export interface SetLoaderAction {
  type: typeof SET_LOADER;
  payload: boolean;
}

export interface SetStepAction {
  type: typeof SET_HOME_STEP;
  payload: number;
}

export interface SetStepListAction {
  type: typeof SET_HOME_STEP_LIST;
  payload: string[] | null;
}

export interface SetEditAction {
  type: typeof SET_HOME_EDIT;
  payload: boolean;
}

export interface SetHomeUserDataAction {
  type: typeof SET_HOME_USER_DATA;
  payload: IHomeUserData;
}

export interface SetHomeInitialDataAction {
  type: typeof SET_HOME_INITIAL_DATA;
  payload: IHomeUserData;
}

export interface SetHomeLoaderAction {
  type: typeof SET_HOME_LOADER;
  payload: boolean;
}

export interface SetHomeFixedLoaderAction {
  type: typeof SET_HOME_FIXED_LOADER;
  payload: boolean;
}

export interface SetHomeConfirmationModalAction {
  type: typeof SET_HOME_CONFIRMATION_MODAL;
  payload: boolean;
}

export interface SetPrepaidCardModalAction {
  type: typeof SET_PREPAID_CARD_MODAL;
  payload: boolean;
}

export interface SetHomeSuccessMessageAction {
  type: typeof SET_HOME_SUCCESS_MESSAGE;
  payload: boolean;
}

export interface HomePageReset {
  type: typeof HOME_PAGE_RESET;
}

export interface FetchUserDataAction {
  type: typeof FETCH_USER_DATA;
}

export interface LogInAction {
  type: typeof LOGIN_USER;
  payload: any;
}

export interface LogInSuccessAction {
  type: typeof LOGIN_USER_SUCCESS;
  payload: ITokenData;
}

export interface LogOutAction {
  type: typeof LOGOUT;
}

export interface StartDefaultLoginAction {
  type: typeof START_DEFAULT_LOGIN;
  payload: IUserData;
}

export interface SendTwilioCodeAction {
  type: typeof SEND_TWILIO_CODE;
  payload: IPhoneParams;
}

export interface VerifyTwilioCodeAction {
  type: typeof VERIFY_TWILIO_CODE;
  // payload: IPhoneParams & { lastStep: number };
  payload: IPhoneParams;
}

export interface SaveEditDataAction {
  type: typeof SAVE_EDIT_DATA;
  payload: IUserDataHome;
}

export interface IHomeUserData {
  [key: string]: any;
}

export interface IHomePageData {
  step: number;
  steps: string[] | null;
  canEdit: boolean;
  loader: boolean;
  fixedLoader: boolean;
  confirmationModal: boolean;
  successMessage: boolean;
  initialData: IHomeUserData | IUserDataHome;
  userData: IHomeUserData | IUserDataHome;
  prepaidCardModal: boolean;
}

export interface SystemState {
  readonly loading: boolean;
  readonly waitingForWebhook: boolean;
  readonly activeTheme: string;
  readonly userData: ITokenData | null;
  readonly homePageData: IHomePageData;
  readonly pushToCardData: IPushToCardData | null;
}

export type ActionTypes =
  | ChangeThemeAction
  | SetLoaderAction
  | LogOutAction
  | LogInAction
  | LogInSuccessAction
  | StartDefaultLoginAction
  | SetStepAction
  | SetStepListAction
  | SetEditAction
  | SetHomeUserDataAction
  | SetHomeInitialDataAction
  | SetHomeLoaderAction
  | SetHomeFixedLoaderAction
  | SetHomeConfirmationModalAction
  | SetHomeSuccessMessageAction
  | HomePageReset
  | SetPrepaidCardModalAction
  | SetPushToCardDataAction
  | SetWaitingForWebhook

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "@root/pages/Maintenance/styles";
import React from 'react';
import maintenanceImage from 'src/assests/images/maintenance.png';

const Maintenance: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Box className={classes.textWrapper}>
        <Typography variant="h3" component="h1">
          Maintenance Notice
        </Typography>
        <Typography>
          Dear Members, Our platform is currently undergoing maintenance and will be unavailable
          until further notice. We will update you as soon as everything is back to normal. We
          apologize for any inconvenience this may cause.
          <br />
          <br />
          Best regards, <br />
          Shop Team.
        </Typography>
      </Box>
      <Box className={classes.imgWrapper}>
        <img className={classes.img} src={maintenanceImage} alt="maintenance" />
      </Box>
    </Grid>
  );
};

export default Maintenance;

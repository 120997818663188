import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flagSelect: {
      display: 'flex',
      alignItems: 'center',
    },
    code: {
      marginLeft: 3,
    },

    selectInput: {
      maxWidth: 125,
      marginRight: 3,
    },

    phoneSelectContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },

    codeWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 6px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      marginRight: 3,
      maxHeight: 56,
    },
    languageInput: {},
    errorInput: {},

    wrapper: { display: 'flex' },
  })
);

import apiClient from '../client';
import ApiUrls from '../apiUrls';

export interface IVerifyResponse {
  data: boolean;
  statusCode: number;
}

const request = (number: string) =>
  apiClient
    .get<IVerifyResponse>(ApiUrls.routingNumberValidate + number)
    .then((response) => response);

export default request;

export const restrictedCountries = [
  'Cuba',
  'Republic of the Congo',
  'Democratic Republic of the Congo',
  'Iran',
  'Iraq',
  'Lebanon',
  'Libya',
  'North Korea',
  'Somalia',
  'Sudan',
  // 'Ukraine',
  'Venezuela',
  'Yemen',
  'Zimbabwe',
];

export interface IRegion {
  id: number;
  code: string;
  name: string;
  countries: ICountry[];
}

export interface ICountry {
  id: number;
  code: string;
  name: string;
}

const reg: any = [
  {
    id: 5,
    code: 'other',
    name: 'Other',
    countries: [
      {
        id: 1,
        code: 'BF',
        name: 'Burkina Faso',
      },
      {
        id: 4,
        code: 'WF',
        name: 'Wallis and Futuna',
      },
      {
        id: 5,
        code: 'BD',
        name: 'Bangladesh',
      },
      {
        id: 8,
        code: 'BN',
        name: 'Brunei',
      },
      {
        id: 9,
        code: 'BO',
        name: 'Bolivia',
      },
      {
        id: 10,
        code: 'BH',
        name: 'Bahrain',
      },
      {
        id: 13,
        code: 'BI',
        name: 'Burundi',
      },
      {
        id: 14,
        code: 'BJ',
        name: 'Benin',
      },
      {
        id: 16,
        code: 'WS',
        name: 'Samoa',
      },
      {
        id: 22,
        code: 'BV',
        name: 'Bouvet Island',
      },
      {
        id: 23,
        code: 'RW',
        name: 'Rwanda',
      },
      {
        id: 24,
        code: 'BR',
        name: 'Brazil',
      },
      {
        id: 25,
        code: 'BW',
        name: 'Botswana',
      },
      {
        id: 28,
        code: 'TM',
        name: 'Turkmenistan',
      },
      {
        id: 30,
        code: 'RE',
        name: 'Reunion',
      },
      {
        id: 31,
        code: 'GU',
        name: 'Guam',
      },
      {
        id: 32,
        code: 'TK',
        name: 'Tokelau',
      },
      {
        id: 34,
        code: 'TJ',
        name: 'Tajikistan',
      },
      {
        id: 35,
        code: 'GS',
        name: 'South Georgia and the South Sandwich Islands',
      },
      {
        id: 36,
        code: 'GW',
        name: 'Guinea-Bissau',
      },
      {
        id: 37,
        code: 'GQ',
        name: 'Equatorial Guinea',
      },
      {
        id: 40,
        code: 'JP',
        name: 'Japan',
      },
      {
        id: 41,
        code: 'TL',
        name: 'East Timor',
      },
      {
        id: 42,
        code: 'GY',
        name: 'Guyana',
      },
      {
        id: 45,
        code: 'GA',
        name: 'Gabon',
      },
      {
        id: 47,
        code: 'GE',
        name: 'Georgia',
      },
      {
        id: 51,
        code: 'GF',
        name: 'French Guiana',
      },
      {
        id: 53,
        code: 'OM',
        name: 'Oman',
      },
      {
        id: 54,
        code: 'GN',
        name: 'Guinea',
      },
      {
        id: 55,
        code: 'JO',
        name: 'Jordan',
      },
      {
        id: 56,
        code: 'TN',
        name: 'Tunisia',
      },
      {
        id: 57,
        code: 'GH',
        name: 'Ghana',
      },
      {
        id: 58,
        code: 'GM',
        name: 'Gambia',
      },
      {
        id: 60,
        code: 'PW',
        name: 'Palau',
      },
      {
        id: 62,
        code: 'PS',
        name: 'Palestinian Territory',
      },
      {
        id: 64,
        code: 'HK',
        name: 'Hong Kong',
      },
      {
        id: 65,
        code: 'PF',
        name: 'French Polynesia',
      },
      {
        id: 66,
        code: 'VE',
        name: 'Venezuela',
      },
      {
        id: 68,
        code: 'PY',
        name: 'Paraguay',
      },
      {
        id: 69,
        code: 'IQ',
        name: 'Iraq',
      },
      {
        id: 73,
        code: 'HM',
        name: 'Heard Island and McDonald Islands',
      },
      {
        id: 75,
        code: 'PG',
        name: 'Papua New Guinea',
      },
      {
        id: 76,
        code: 'PK',
        name: 'Pakistan',
      },
      {
        id: 77,
        code: 'ZM',
        name: 'Zambia',
      },
      {
        id: 79,
        code: 'PE',
        name: 'Peru',
      },
      {
        id: 81,
        code: 'EG',
        name: 'Egypt',
      },
      {
        id: 82,
        code: 'ZA',
        name: 'South Africa',
      },
      {
        id: 83,
        code: 'EC',
        name: 'Ecuador',
      },
      {
        id: 85,
        code: 'VN',
        name: 'Vietnam',
      },
      {
        id: 87,
        code: 'EH',
        name: 'Western Sahara',
      },
      {
        id: 88,
        code: 'PN',
        name: 'Pitcairn',
      },
      {
        id: 89,
        code: 'SO',
        name: 'Somalia',
      },
      {
        id: 90,
        code: 'ET',
        name: 'Ethiopia',
      },
      {
        id: 91,
        code: 'PH',
        name: 'Philippines',
      },
      {
        id: 92,
        code: 'SB',
        name: 'Solomon Islands',
      },
      {
        id: 93,
        code: 'ER',
        name: 'Eritrea',
      },
      {
        id: 94,
        code: 'SA',
        name: 'Saudi Arabia',
      },
      {
        id: 96,
        code: 'MA',
        name: 'Morocco',
      },
      {
        id: 99,
        code: 'ZW',
        name: 'Zimbabwe',
      },
      {
        id: 101,
        code: 'UZ',
        name: 'Uzbekistan',
      },
      {
        id: 102,
        code: 'MG',
        name: 'Madagascar',
      },
      {
        id: 104,
        code: 'MO',
        name: 'Macao',
      },
      {
        id: 105,
        code: 'MM',
        name: 'Myanmar',
      },
      {
        id: 106,
        code: 'ML',
        name: 'Mali',
      },
      {
        id: 108,
        code: 'MW',
        name: 'Malawi',
      },
      {
        id: 109,
        code: 'MN',
        name: 'Mongolia',
      },
      {
        id: 110,
        code: 'MV',
        name: 'Maldives',
      },
      {
        id: 111,
        code: 'MU',
        name: 'Mauritius',
      },
      {
        id: 114,
        code: 'MH',
        name: 'Marshall Islands',
      },
      {
        id: 115,
        code: 'MP',
        name: 'Northern Mariana Islands',
      },
      {
        id: 116,
        code: 'MR',
        name: 'Mauritania',
      },
      {
        id: 118,
        code: 'UG',
        name: 'Uganda',
      },
      {
        id: 119,
        code: 'MY',
        name: 'Malaysia',
      },
      {
        id: 121,
        code: 'IL',
        name: 'Israel',
      },
      {
        id: 122,
        code: 'IO',
        name: 'British Indian Ocean Territory',
      },
      {
        id: 123,
        code: 'FK',
        name: 'Falkland Islands',
      },
      {
        id: 126,
        code: 'SH',
        name: 'Saint Helena',
      },
      {
        id: 127,
        code: 'FM',
        name: 'Micronesia',
      },
      {
        id: 128,
        code: 'TZ',
        name: 'Tanzania',
      },
      {
        id: 129,
        code: 'FJ',
        name: 'Fiji',
      },
      {
        id: 132,
        code: 'CK',
        name: 'Cook Islands',
      },
      {
        id: 134,
        code: 'NF',
        name: 'Norfolk Island',
      },
      {
        id: 135,
        code: 'VU',
        name: 'Vanuatu',
      },
      {
        id: 136,
        code: 'NC',
        name: 'New Caledonia',
      },
      {
        id: 138,
        code: 'NE',
        name: 'Niger',
      },
      {
        id: 140,
        code: 'NA',
        name: 'Namibia',
      },
      {
        id: 141,
        code: 'NP',
        name: 'Nepal',
      },
      {
        id: 142,
        code: 'CI',
        name: 'Ivory Coast',
      },
      {
        id: 144,
        code: 'NR',
        name: 'Nauru',
      },
      {
        id: 145,
        code: 'NZ',
        name: 'New Zealand',
      },
      {
        id: 146,
        code: 'NG',
        name: 'Nigeria',
      },
      {
        id: 148,
        code: 'NU',
        name: 'Niue',
      },
      {
        id: 149,
        code: 'CM',
        name: 'Cameroon',
      },
      {
        id: 152,
        code: 'CL',
        name: 'Chile',
      },
      {
        id: 153,
        code: 'CG',
        name: 'Republic of the Congo',
      },
      {
        id: 166,
        code: 'CD',
        name: 'Democratic Republic of the Congo',
      },
      {
        id: 183,
        code: 'KZ',
        name: 'Kazakhstan',
      },
      {
        id: 197,
        code: 'YE',
        name: 'Yemen',
      },
      {
        id: 223,
        code: 'TG',
        name: 'Togo',
      },
      {
        id: 249,
        code: 'MZ',
        name: 'Mozambique',
      },
      {
        id: 169,
        code: 'SR',
        name: 'Suriname',
      },
      {
        id: 202,
        code: 'TR',
        name: 'Turkey',
      },
      {
        id: 210,
        code: 'TV',
        name: 'Tuvalu',
      },
      {
        id: 219,
        code: 'LY',
        name: 'Libya',
      },
      {
        id: 234,
        code: 'AQ',
        name: 'Antarctica',
      },
      {
        id: 155,
        code: 'CO',
        name: 'Colombia',
      },
      {
        id: 163,
        code: 'CF',
        name: 'Central African Republic',
      },
      {
        id: 179,
        code: 'SL',
        name: 'Sierra Leone',
      },
      {
        id: 186,
        code: 'SG',
        name: 'Singapore',
      },
      {
        id: 193,
        code: 'DZ',
        name: 'Algeria',
      },
      {
        id: 200,
        code: 'LB',
        name: 'Lebanon',
      },
      {
        id: 220,
        code: 'TF',
        name: 'French Southern Territories',
      },
      {
        id: 247,
        code: 'QA',
        name: 'Qatar',
      },
      {
        id: 156,
        code: 'CN',
        name: 'China',
      },
      {
        id: 173,
        code: 'KE',
        name: 'Kenya',
      },
      {
        id: 201,
        code: 'UM',
        name: 'United States Minor Outlying Islands',
      },
      {
        id: 217,
        code: 'TO',
        name: 'Tonga',
      },
      {
        id: 239,
        code: 'AM',
        name: 'Armenia',
      },
      {
        id: 164,
        code: 'KG',
        name: 'Kyrgyzstan',
      },
      {
        id: 174,
        code: 'ST',
        name: 'Sao Tome and Principe',
      },
      {
        id: 189,
        code: 'SC',
        name: 'Seychelles',
      },
      {
        id: 196,
        code: 'DJ',
        name: 'Djibouti',
      },
      {
        id: 203,
        code: 'TW',
        name: 'Taiwan',
      },
      {
        id: 224,
        code: 'TD',
        name: 'Chad',
      },
      {
        id: 238,
        code: 'IN',
        name: 'India',
      },
      {
        id: 158,
        code: 'CX',
        name: 'Christmas Island',
      },
      {
        id: 165,
        code: 'SZ',
        name: 'Swaziland',
      },
      {
        id: 180,
        code: 'KH',
        name: 'Cambodia',
      },
      {
        id: 188,
        code: 'SD',
        name: 'Sudan',
      },
      {
        id: 215,
        code: 'LR',
        name: 'Liberia',
      },
      {
        id: 236,
        code: 'AO',
        name: 'Angola',
      },
      {
        id: 251,
        code: 'AZ',
        name: 'Azerbaijan',
      },
      {
        id: 159,
        code: 'CC',
        name: 'Cocos Islands',
      },
      {
        id: 167,
        code: 'KI',
        name: 'Kiribati',
      },
      {
        id: 175,
        code: 'SS',
        name: 'South Sudan',
      },
      {
        id: 184,
        code: 'KP',
        name: 'North Korea',
      },
      {
        id: 199,
        code: 'UY',
        name: 'Uruguay',
      },
      {
        id: 208,
        code: 'YT',
        name: 'Mayotte',
      },
      {
        id: 233,
        code: 'IR',
        name: 'Iran',
      },
      {
        id: 242,
        code: 'ID',
        name: 'Indonesia',
      },
      {
        id: 160,
        code: 'CV',
        name: 'Cape Verde',
      },
      {
        id: 168,
        code: 'KM',
        name: 'Comoros',
      },
      {
        id: 195,
        code: 'SN',
        name: 'Senegal',
      },
      {
        id: 213,
        code: 'LS',
        name: 'Lesotho',
      },
      {
        id: 227,
        code: 'AE',
        name: 'United Arab Emirates',
      },
      {
        id: 235,
        code: 'AR',
        name: 'Argentina',
      },
      {
        id: 244,
        code: 'AF',
        name: 'Afghanistan',
      },
      {
        id: 170,
        code: 'SY',
        name: 'Syria',
      },
      {
        id: 176,
        code: 'KR',
        name: 'South Korea',
      },
      {
        id: 185,
        code: 'KW',
        name: 'Kuwait',
      },
      {
        id: 205,
        code: 'LA',
        name: 'Laos',
      },
      {
        id: 221,
        code: 'TH',
        name: 'Thailand',
      },
      {
        id: 240,
        code: 'AU',
        name: 'Australia',
      },
      {
        id: 248,
        code: 'Other',
        name: 'Other',
      },
      {
        id: 204,
        code: 'LK',
        name: 'Sri Lanka',
      },
      {
        id: 241,
        code: 'AS',
        name: 'American Samoa',
      },
    ],
  },
  {
    id: 3,
    code: 'europe',
    name: 'Europe',
    countries: [
      {
        id: 2,
        code: 'BG',
        name: 'Bulgaria',
      },
      {
        id: 7,
        code: 'BE',
        name: 'Belgium',
      },
      {
        id: 12,
        code: 'BA',
        name: 'Bosnia and Herzegovina',
      },
      {
        id: 20,
        code: 'RU',
        name: 'Russia',
      },
      {
        id: 21,
        code: 'BY',
        name: 'Belarus',
      },
      {
        id: 27,
        code: 'JE',
        name: 'Jersey',
      },
      {
        id: 29,
        code: 'RS',
        name: 'Serbia',
      },
      {
        id: 33,
        code: 'RO',
        name: 'Romania',
      },
      {
        id: 38,
        code: 'GR',
        name: 'Greece',
      },
      {
        id: 46,
        code: 'GI',
        name: 'Gibraltar',
      },
      {
        id: 49,
        code: 'GB',
        name: 'United Kingdom',
      },
      {
        id: 50,
        code: 'GG',
        name: 'Guernsey',
      },
      {
        id: 59,
        code: 'HR',
        name: 'Croatia',
      },
      {
        id: 67,
        code: 'HU',
        name: 'Hungary',
      },
      {
        id: 70,
        code: 'PT',
        name: 'Portugal',
      },
      {
        id: 71,
        code: 'SJ',
        name: 'Svalbard and Jan Mayen',
      },
      {
        id: 78,
        code: 'EE',
        name: 'Estonia',
      },
      {
        id: 84,
        code: 'IT',
        name: 'Italy',
      },
      {
        id: 86,
        code: 'PL',
        name: 'Poland',
      },
      {
        id: 97,
        code: 'ES',
        name: 'Spain',
      },
      {
        id: 98,
        code: 'MD',
        name: 'Moldova',
      },
      {
        id: 100,
        code: 'ME',
        name: 'Montenegro',
      },
      {
        id: 103,
        code: 'MC',
        name: 'Monaco',
      },
      {
        id: 107,
        code: 'MK',
        name: 'Macedonia',
      },
      {
        id: 112,
        code: 'MT',
        name: 'Malta',
      },
      {
        id: 120,
        code: 'IM',
        name: 'Isle of Man',
      },
      {
        id: 124,
        code: 'FR',
        name: 'France',
      },
      {
        id: 125,
        code: 'FO',
        name: 'Faroe Islands',
      },
      {
        id: 130,
        code: 'FI',
        name: 'Finland',
      },
      {
        id: 133,
        code: 'NO',
        name: 'Norway',
      },
      {
        id: 139,
        code: 'NL',
        name: 'Netherlands',
      },
      {
        id: 143,
        code: 'XK',
        name: 'Kosovo',
      },
      {
        id: 147,
        code: 'CH',
        name: 'Switzerland',
      },
      {
        id: 212,
        code: 'LI',
        name: 'Liechtenstein',
      },
      {
        id: 232,
        code: 'AL',
        name: 'Albania',
      },
      {
        id: 154,
        code: 'CY',
        name: 'Cyprus',
      },
      {
        id: 191,
        code: 'DE',
        name: 'Germany',
      },
      {
        id: 225,
        code: 'AD',
        name: 'Andorra',
      },
      {
        id: 243,
        code: 'AX',
        name: 'Aland Islands',
      },
      {
        id: 172,
        code: 'SK',
        name: 'Slovakia',
      },
      {
        id: 211,
        code: 'LV',
        name: 'Latvia',
      },
      {
        id: 226,
        code: 'IS',
        name: 'Iceland',
      },
      {
        id: 187,
        code: 'SE',
        name: 'Sweden',
      },
      {
        id: 245,
        code: 'UA',
        name: 'Ukraine',
      },
      {
        id: 246,
        code: 'AT',
        name: 'Austria',
      },
      {
        id: 207,
        code: 'DK',
        name: 'Denmark',
      },
      {
        id: 216,
        code: 'LT',
        name: 'Lithuania',
      },
      {
        id: 250,
        code: 'IE',
        name: 'Ireland',
      },
      {
        id: 181,
        code: 'SM',
        name: 'San Marino',
      },
      {
        id: 162,
        code: 'CZ',
        name: 'Czech Republic',
      },
      {
        id: 177,
        code: 'SI',
        name: 'Slovenia',
      },
      {
        id: 214,
        code: 'LU',
        name: 'Luxembourg',
      },
      {
        id: 229,
        code: 'VA',
        name: 'Vatican',
      },
    ],
  },
  {
    id: 1,
    code: 'northamerica',
    name: 'North America',
    countries: [
      {
        id: 3,
        code: 'BB',
        name: 'Barbados',
      },
      {
        id: 6,
        code: 'BM',
        name: 'Bermuda',
      },
      {
        id: 11,
        code: 'BL',
        name: 'Saint Barthelemy',
      },
      {
        id: 17,
        code: 'JM',
        name: 'Jamaica',
      },
      {
        id: 18,
        code: 'BQ',
        name: 'Bonaire, Saint Eustatius and Saba ',
      },
      {
        id: 19,
        code: 'BZ',
        name: 'Belize',
      },
      {
        id: 26,
        code: 'BS',
        name: 'Bahamas',
      },
      {
        id: 39,
        code: 'GP',
        name: 'Guadeloupe',
      },
      {
        id: 43,
        code: 'GT',
        name: 'Guatemala',
      },
      {
        id: 44,
        code: 'SV',
        name: 'El Salvador',
      },
      {
        id: 48,
        code: 'GD',
        name: 'Grenada',
      },
      {
        id: 52,
        code: 'GL',
        name: 'Greenland',
      },
      {
        id: 61,
        code: 'HN',
        name: 'Honduras',
      },
      {
        id: 63,
        code: 'HT',
        name: 'Haiti',
      },
      {
        id: 72,
        code: 'PR',
        name: 'Puerto Rico',
      },
      {
        id: 74,
        code: 'PA',
        name: 'Panama',
      },
      {
        id: 80,
        code: 'PM',
        name: 'Saint Pierre and Miquelon',
      },
      {
        id: 95,
        code: 'MF',
        name: 'Saint Martin',
      },
      {
        id: 113,
        code: 'MS',
        name: 'Montserrat',
      },
      {
        id: 117,
        code: 'MQ',
        name: 'Martinique',
      },
      {
        id: 131,
        code: 'NI',
        name: 'Nicaragua',
      },
      {
        id: 137,
        code: 'MX',
        name: 'Mexico',
      },
      {
        id: 150,
        code: 'CA',
        name: 'Canada',
      },
      {
        id: 151,
        code: 'CR',
        name: 'Costa Rica',
      },
      {
        id: 178,
        code: 'KN',
        name: 'Saint Kitts and Nevis',
      },
      {
        id: 237,
        code: 'AW',
        name: 'Aruba',
      },
      {
        id: 230,
        code: 'VI',
        name: 'U.S. Virgin Islands',
      },
      {
        id: 157,
        code: 'CW',
        name: 'Curacao',
      },
      {
        id: 182,
        code: 'KY',
        name: 'Cayman Islands',
      },
      {
        id: 209,
        code: 'LC',
        name: 'Saint Lucia',
      },
      {
        id: 218,
        code: 'TC',
        name: 'Turks and Caicos Islands',
      },
      {
        id: 198,
        code: 'VG',
        name: 'British Virgin Islands',
      },
      {
        id: 222,
        code: 'VC',
        name: 'Saint Vincent and the Grenadines',
      },
      {
        id: 192,
        code: 'DM',
        name: 'Dominica',
      },
      {
        id: 228,
        code: 'AI',
        name: 'Anguilla',
      },
      {
        id: 206,
        code: 'TT',
        name: 'Trinidad and Tobago',
      },
      {
        id: 161,
        code: 'CU',
        name: 'Cuba',
      },
      {
        id: 194,
        code: 'US',
        name: 'United States',
      },
      {
        id: 231,
        code: 'AG',
        name: 'Antigua and Barbuda',
      },
      {
        id: 171,
        code: 'SX',
        name: 'Sint Maarten',
      },
      {
        id: 190,
        code: 'DO',
        name: 'Dominican Republic',
      },
    ],
  },
  {
    id: 6,
    code: 'bhutan',
    name: 'Bhutan',
    countries: [
      {
        id: 15,
        code: 'BT',
        name: 'Bhutan',
      },
    ],
  },
  {
    id: 2,
    code: 'southamerica',
    name: 'South America',
    countries: [],
  },
  {
    id: 4,
    code: 'asia',
    name: 'Asia',
    countries: [],
  },
];

export default reg;

import apiClient from '../client';
import ApiUrls from '../apiUrls';
import { AxiosResponse } from 'axios';

export interface IVerifyResponseData {
  email: string;
  isSameAddress: boolean;
  isValid: boolean;
  inUse: boolean;
  statusCode?: number;
  message?: string;
}

export type VerifyResponse = AxiosResponse<IVerifyResponseData>;

const request = (query: string) =>
  apiClient.get(ApiUrls.emailVerifyExceptLogged + query).then((response) => response);

export default request;

import {
  ActionTypes,
  HOME_PAGE_RESET,
  LOGIN_USER_SUCCESS,
  SET_HOME_CONFIRMATION_MODAL,
  SET_HOME_EDIT,
  SET_HOME_FIXED_LOADER,
  SET_HOME_INITIAL_DATA,
  SET_HOME_LOADER,
  SET_HOME_STEP,
  SET_HOME_STEP_LIST,
  SET_HOME_SUCCESS_MESSAGE,
  SET_HOME_USER_DATA,
  SET_LOADER,
  SET_PREPAID_CARD_MODAL,
  SET_PUSH_TO_CARD_DATA,
  SET_WAITING_FOR_WEBHOOK,
  SystemState,
} from './types';

const initialState: SystemState = {
  loading: false,
  activeTheme: 'theme1',
  waitingForWebhook: false,
  userData: {
    accessToken: localStorage.getItem('AT') || '',
    refreshToken: localStorage.getItem('RT') || '',
    userId: '',
  },
  homePageData: {
    step: 0,
    steps: null,
    canEdit: true,
    loader: true,
    fixedLoader: false,
    confirmationModal: false,
    prepaidCardModal: false,
    successMessage: false,
    initialData: {},
    userData: {
      // method: '',
    },
  },
  pushToCardData: null,
};

export function systemReducer(state = initialState, action: ActionTypes): SystemState {
  switch (action.type) {
    case SET_LOADER:
      return { ...state, loading: action.payload };
    
    case LOGIN_USER_SUCCESS:
      return { ...state, userData: action.payload };
    
    case SET_HOME_STEP:
      return { ...state, homePageData: { ...state.homePageData, step: action.payload } };
    
    case SET_HOME_STEP_LIST:
      return { ...state, homePageData: { ...state.homePageData, steps: action.payload } };
    
    case SET_HOME_EDIT:
      return { ...state, homePageData: { ...state.homePageData, canEdit: action.payload } };
    
    case SET_HOME_USER_DATA:
      return {
        ...state,
        homePageData: { ...state.homePageData, userData: { ...state.homePageData.userData, ...action.payload } },
      };
    
    case SET_HOME_INITIAL_DATA:
      return { ...state, homePageData: { ...state.homePageData, initialData: { ...action.payload } } };
    
    case SET_HOME_LOADER:
      return { ...state, homePageData: { ...state.homePageData, loader: action.payload } };
    
    case SET_HOME_FIXED_LOADER:
      return { ...state, homePageData: { ...state.homePageData, fixedLoader: action.payload } };
    
    case SET_HOME_CONFIRMATION_MODAL:
      return { ...state, homePageData: { ...state.homePageData, confirmationModal: action.payload } };
    
    case SET_PREPAID_CARD_MODAL:
      return { ...state, homePageData: { ...state.homePageData, prepaidCardModal: action.payload } };
    
    case SET_HOME_SUCCESS_MESSAGE:
      return { ...state, homePageData: { ...state.homePageData, successMessage: action.payload } };
    
    case HOME_PAGE_RESET: {
      return { ...state, homePageData: initialState.homePageData };
    }
    case SET_PUSH_TO_CARD_DATA: {
      return { ...state, pushToCardData: action.payload };
    }
    case SET_WAITING_FOR_WEBHOOK: {
      return { ...state, waitingForWebhook: action.payload };
    }
    default:
      return state;
  }
}

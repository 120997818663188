import apiClient from '../client';
import { INSTANCE_TYPES } from '@root/enums/instance-types';



interface IRedirectionTokenResponse {
  redirectionToken: string;
  redirectionUrl: string;
  wrongDomain: boolean;
}

const getRedirectionToken = async (): Promise<IRedirectionTokenResponse | null> => {
  
  try {
    
    const response = await apiClient.get(`/auth/get-redirection-token?instanceType=${INSTANCE_TYPES.SHOP}`);
    return response.data.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};


export  {
  getRedirectionToken,
};

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { IOption } from '../../types';
import { useSelector } from 'react-redux';
import { selectCountryList } from '../../store/entities/selectors';

interface CountryType {
  label: string;

  value(value: any): React.ReactNode;
}

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined' && isoCode.length === 2
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : '';
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface IProps {
  value?: string;
  id: string;
  reinitialize: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  availableCountryCodes?: string[];
  label?: string;
  required?: boolean;

  onChange(v: string): void;

  onBlur?(): void;
}

const CountrySelect: React.FC<IProps> = ({
  value,
  id,
  onChange,
  reinitialize,
  error,
  helperText,
  disabled = false,
  label,
  availableCountryCodes = [],
  required,
  onBlur,
}) => {
  const classes = useStyles();
  let allCountries = useSelector(selectCountryList);

  if (id === 'bankCountry') {
    //! Excluded countries from bank country select MS-1870
    allCountries = allCountries.filter((country: IOption) => !['US'].includes(country.value));
  }

  const options = availableCountryCodes.length
    ? allCountries.filter((item: IOption) => availableCountryCodes.includes(item.value))
    : allCountries;

  const onSelectChange = (e: any, selectedOption: any = []) => {
    onChange(selectedOption?.value || '');
  };

  const option = options.find((item: IOption) => item.value === value);

  if (value === undefined && reinitialize) {
    return (
      <TextField
        autoComplete="new-password"
        name="firstName"
        variant="outlined"
        required
        disabled={disabled}
        fullWidth
        id="firstName"
        label={label || 'Choose a country'}
      />
    );
  }

  return (
    <Autocomplete
      id={id}
      options={options as CountryType[] | []}
      value={option}
      onChange={onSelectChange}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      disabled={disabled}
      fullWidth
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.value)}</span>
          {option.label}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Choose a country'}
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          required={!!required}
          onBlur={onBlur}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
